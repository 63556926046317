import React from "react";
import { Route, Switch } from "react-router-dom";
import CustomerCommonListComponent from "../Customer/CustomerCommonListComponent";
import CustomerVinListingComponent from "../Customer/CustomerVinListingComponent";
import CustomerBusinessListingComponent from "../Customer/CustomerBusinessListingComponent";
import CustomerDetailsComponent from "../Customer/CustomerDetailsComponent";
import CustomerLandingComponent from "../Customer/CustomerLandingComponent";
import CustomerPhoneListingComponent from "../Customer/CustomerPhoneListingComponent";
import CustomerMaintenanceComponent from "../Customer/CustomerMaintenanceComponent";

const AppRouter = (props) => {
  return (
    <div data-testid='appRouter-testid'>
      <Switch>
        <Route path="/" exact component={CustomerLandingComponent} />
        <Route
          path="/customer-common-list"
          component={CustomerCommonListComponent}
        />
        <Route
          path="/customer-vin-list"
          component={CustomerVinListingComponent}
        />
        <Route
          path="/customer-business-list"
          component={CustomerBusinessListingComponent}
        />
        <Route
          path="/customer-phone-list"
          component={CustomerPhoneListingComponent}
        />
        <Route path="/customer-details" component={CustomerDetailsComponent} />
        <Route path="/customer-error" component={()=>(<CustomerMaintenanceComponent {...props}/>)} />
      </Switch>
    </div>
  );
};

export default AppRouter;
