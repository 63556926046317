import React, { useState, useContext } from "react";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CustomerContext } from "../../ContextApi/CustomersContext";
import { modalStyles } from "../../utilities/modal";
import { CHANGE_EMAIL_REGEX } from '../../utilities/regexPatterns'
import { GenericModal } from "../GapModal/generic-modal";
import {message} from '../../utilities/message';

function emailRegexError(inputValue) {
  const emailRegex = CHANGE_EMAIL_REGEX;
  return emailRegex.test(inputValue) || inputValue.length === 0 ? "" : "Email is not valid";
}

function buttonDisable(condition, classes) {
  return condition ? clsx(classes.buttonStyle, "disableButton") : clsx(classes.buttonStyle, "buttonAccept")
}

function loadingNewEmailVerification(loadingForNewEmailVerification) {
  return loadingForNewEmailVerification ? <CircularProgress /> : null;
}

function ChangeEmail(props) {
  const { REACT_APP_API_URL } = process.env;
  const { setLoginEmailId, setEmailVerified } = props;
  const [openAlert, setOpenAlert] = React.useState(false);
  const [, , tokenAndRolesCapture, [customerDetails, setcustomerDetails]] = useContext(CustomerContext);
  const [loadingForChangeEmail, setLoadingForChangeEmail] = useState(false);
  const [loadingForNewEmailVerification, setLoadingForNewEmailVerification] = useState(false);
  const classes = modalStyles();
  const [checkedState, setCheckedState] = useState(false);
  const [changedLoginEmailId, setChangedLoginEmailId] = useState("");
  const [changeLoginEmailIdInput, setChangeLoginEmailIdInput] = useState("");
  const [changeLoginEmailIdError, setChangeLoginEmailIdError] = useState("");
  const [changeLoginEmailIdResponse, setChangeLoginEmailIdResponse] = useState(false);
  const [verifyNewEmailResponse, setVerifyNewEmailResponse] = useState(false);
  const [openNewEmailVerifiedAlert, setOpenNewEmailVerifiedAlert] = useState(false);
  const [changeLoginEmailIdErrorResponse, setChangeLoginEmailIdErrorResponse] = useState("");
  const [verifyNewEmailErrorResponse, setVerifyNewEmailErrorResponse] = useState("");
  const [disableVerifyNewEmailButton, setDisableVerifyNewEmailButton] = useState(false);
  let disableButton = false;
  if (checkedState === false || changeLoginEmailIdInput === "" || changeLoginEmailIdResponse === true || changeLoginEmailIdError !== "" || !props.isCCDataPresent) {
    disableButton = true;
  }
  const handleChange = () => {
    setCheckedState(!checkedState);
  };
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
    setChangeLoginEmailIdInput("");
  };
  const changeLoginEmailIdData = { guid: props.guid, email: changeLoginEmailIdInput, };
  const handleChangeLoginEmailId = async () => {
    const tokenResponse = await tokenAndRolesCapture();
    setOpenModal(false);
    setLoadingForChangeEmail(true);
    setChangedLoginEmailId(changeLoginEmailIdInput);
    const config = {
      headers: { "Content-type": "application/json", Authorization: `Bearer ${tokenResponse.idToken}`,
      },
    };
    axios.post(`${REACT_APP_API_URL}/gap/change/login-email/id`, changeLoginEmailIdData, config)
      .then((response) => {
        setLoadingForChangeEmail(false);
        setOpenAlert(true);
        if (response.data.code === 200) {
          setChangeLoginEmailIdResponse(true);
          setTimeout(()=>setOpenAlert(false),5000)
        } else if(response.data.code === 301) {
          setChangeLoginEmailIdResponse(false);
          setChangeLoginEmailIdErrorResponse("Email ID already in use – The entered Email ID is already in use, please try again with a different email id.");
          setChangedLoginEmailId("");
        } else {
          setChangeLoginEmailIdResponse(false);
          setChangeLoginEmailIdErrorResponse("Cannot change your Email ID at this moment. Please try again later.");
          setChangedLoginEmailId("");
        }
        disableButton = true;
      })
      .catch((error) => {
        setLoadingForChangeEmail(false);
        setChangeLoginEmailIdErrorResponse("Cannot change your Email ID at this moment. Please try again later.");
        setChangedLoginEmailId("");
      });
  };
  const verifyNewEmailData = { guid: props.guid, opType: "update" };
  const handleVerifyNewEmail = async () => {
    const tokenResponse = await tokenAndRolesCapture();
    setLoadingForNewEmailVerification(true);
    const config = {
      headers: { "Content-type": "application/json", Authorization: `Bearer ${tokenResponse.idToken}` },
    };
    axios.post(`${REACT_APP_API_URL}/gap/activate/user`, verifyNewEmailData, config)
      .then((response) => {
        setLoadingForNewEmailVerification(false);
        setOpenNewEmailVerifiedAlert(true);
        if (response.data.code === 200) {
          setEmailVerified(true);
          setVerifyNewEmailResponse(true);
          setTimeout(()=>setOpenNewEmailVerifiedAlert(false),5000)
          setLoginEmailId(changedLoginEmailId);
          setDisableVerifyNewEmailButton(true);
          setcustomerDetails({
            customerDetail: { ...customerDetails.customerDetail, accountEmailAddress: changedLoginEmailId
            }
          });
        } else {
          setVerifyNewEmailResponse(false);
          setVerifyNewEmailErrorResponse("Cannot verify your Email ID at this moment. Please try again later.");
        }
      })
      .catch((error) => {
        setLoadingForNewEmailVerification(false);
        setVerifyNewEmailErrorResponse("Cannot verify your Email ID at this moment. Please try again later.");
      });
  };
  function emailValidation(event) {
    let errorMessage = "";
    const inputValue = event.target.value;
    errorMessage = emailRegexError(inputValue);
    setChangeLoginEmailIdInput(inputValue);
    setChangeLoginEmailIdError(errorMessage);
  }

  const isDisabled = () => !props.isCCDataPresent;
  return (
    <div data-testid="changeEmail-testid">
      <Paper style={{ marginTop: "0px", padding: "15px" }}>
        <h3>Change Login Email ID</h3>
        <Collapse in={openAlert}>{changeLoginEmailIdResponse ? (
          <div>
            <Alert severity="success"
              action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpenAlert(false); }}><CloseIcon fontSize="inherit" /></IconButton>}
            >Login Email Id has been changed successfully, please click on{" "}<b>Verify New Email</b> for verifying the new Login Email Id.
            </Alert>
          </div>
        ) : (
          <div>
            <Alert severity="error"
              action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpenAlert(false); }} > <CloseIcon fontSize="inherit" /></IconButton>}
            >{changeLoginEmailIdErrorResponse}
            </Alert>
          </div>)}
        </Collapse>
        <Grid container direction="row" justify="space-between" alignItems="center" style={{ marginTop: "10px", marginBottom: "10px" }} >
          <Grid item>
            <TextField id="outlined-basic" disabled={isDisabled()} variant="outlined" name="changeEmail" value={changeLoginEmailIdInput} helperText={changeLoginEmailIdError}
              onChange={(e) => emailValidation(e)} label="Enter Login Email ID" data-testid="changeEmailInputField-testid" required />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={<Checkbox checked={checkedState} onChange={handleChange} name="checkedB" color="primary" disabled={isDisabled()} />}
              label={<b>I have verified this User Identity</b>} data-testid="changeEmailCheckbox-testid" />
          </Grid>
          {loadingForChangeEmail ? <CircularProgress /> : null}
          <Grid item>
            <Button color="primary" style={{ fontWeight: "bolder" }} disabled={disableButton} variant="outlined" onClick={() => { handleOpen(); }}
              data-testid="changeEmailButton-testid" className={buttonDisable(disableButton, classes)}
            >Change</Button>
            <GenericModal open={openModal} handleClose={handleClose} handleOk={handleChangeLoginEmailId} title={message?.changeEmail?.title} body={message?.changeEmail?.body} />
          </Grid>
        </Grid>
        <Collapse in={openNewEmailVerifiedAlert}>{verifyNewEmailResponse ? (
          <div>
            <Alert severity="success"
              action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpenNewEmailVerifiedAlert(false); }} ><CloseIcon fontSize="inherit" />
              </IconButton>}
            ><b>The email address has been verified successfully.</b>
            </Alert>
          </div>
        ) : (
          <div>
            <Alert severity="error"
              action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpenNewEmailVerifiedAlert(false); }}> <CloseIcon fontSize="inherit" />
              </IconButton>}
            > {verifyNewEmailErrorResponse}
            </Alert>
          </div>
        )}
        </Collapse>
        {changeLoginEmailIdResponse ? (
          <Paper style={{ padding: "10px" }} data-testid='emailVerification-testid'>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item md={8}>
                <b><p> <b>NOTE TO REPS:</b> This new account has a pending email verification status. An activation link has been successfully sent to the customer's email:
                  &nbsp;<b>{changedLoginEmailId}</b> </p>
                  <p> In order to activate, please ask the customer to click on the link in the activation email received.</p>
                  <br /><b>OR</b> <br /><p> You can activate the account on their behalf by clicking on Verify New Email button. </p>
                </b>
              </Grid>
              {loadingNewEmailVerification(loadingForNewEmailVerification)}
              <Grid item>
                <Button size="medium" variant="outlined" className={buttonDisable(disableVerifyNewEmailButton, classes)} disabled={disableVerifyNewEmailButton}
                  onClick={() => { handleVerifyNewEmail(); }} data-testid='verifyNewEmaitButton-testid'> Verify New Email</Button>
              </Grid>
            </Grid>
          </Paper>) : null}
      </Paper>
    </div>
  );
}

export default ChangeEmail;
