export const VIN_HEAD_CELL = {
    id: "vin",
    numeric: false,
    disablePadding: false,
    label: "VIN",
};

export const PHONE_HEAD_CELL = {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
};

export const EMAIL_HEAD_CELL = {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
};
export const FIRST_NAME_HEAD_CELL = {
    id: "firstName",
    numeric: false,
    disablePadding: false,
    label: "First Name",
};
export const LAST_NAME_HEAD_CELL = {
    id: "lastName",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
};
export const STATUS_HEAD_CELL = {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Email Verified",
};
export const OWNERSHIPTYPE_HEAD_CELL = {
    id: "ownershipType",
    numeric: false,
    disablePadding: false,
    label: "Ownership Type",
};
export const ACCOUNTCREATEDDATE_HEAD_CELL = {
    id: "accountCreatedDate",
    numeric: false,
    disablePadding: false,
    label: "Account Created Date",
};

export const ACCOUNTTYPE_HEAD_CELL = {
    id: "ownershipType",
    numeric: false,
    disablePadding: false,
    label: "Account Type",
};

export const BUSINESSNAME_HEAD_CELL = {
    id: "businessName",
    numeric: false,
    disablePadding: false,
    label: "Business Name",
};
