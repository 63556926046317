import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import OwnerShipInfoComponent from "../OwnerInfo/OwnershipInfoComponent";
import AccountInfoComponent from "../AccountInfo/AccountInfoComponent";
import { useLocation } from "react-router-dom";
import { CustomerContext } from "../../ContextApi/CustomersContext";
import axios from "axios";
import { LinearProgress } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "1px",
  },
}));

let ownershipInfoData = {};
let userDetails = {};
export default function CustomerDetailsComponent() {
  const classes = useStyles();
  const location = useLocation();
  const { REACT_APP_API_URL } = process.env;
  const [value, setValue] = React.useState(0);
  let [email, setEmail] = React.useState("");
  const [, , tokenAndRolesCapture, [customerDetails, setcustomerDetails]] =
    useContext(CustomerContext);

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(async () => {
    const emailId = window.sessionStorage.getItem("email");
    if (customerDetails === undefined || isEmpty(customerDetails)) {
      await handleUserDetails(emailId);
    }
    setEmail(emailId);
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("email", email);
  }, [email]);

  async function userDetailsApiCall(email, idToken) {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    };
    const data = {
      email: email,
    };

    await axios
      .post(`${REACT_APP_API_URL}/gap/user-account-info`, data, config)
      .then((response) => {
        setcustomerDetails({ customerDetail: response.data.results });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  async function handleUserDetails(email) {
    tokenAndRolesCapture().then((tokenResponse) => {
      userDetailsApiCall(email, tokenResponse.idToken);
    });
  }

  function checkCustomerDetails(customerDetails) {
    if (!isEmpty(customerDetails)) {
      userDetails = customerDetails.customerDetail;
      ownershipInfoData = {
        customerId: userDetails?.customerId,
      };
      email = location.emailId;
      if (!userDetails || isEmpty(userDetails)) {
        return <h3 style={{ textAlign: "center" }}>User details not available</h3>;
      }
      return value === 0 ? (
        <AccountInfoComponent userDetails={userDetails} />
      ) : (
        <OwnerShipInfoComponent
          customerId={userDetails?.customerId}
          guid={userDetails?.guid}
          ownershipInfoData={ownershipInfoData}
        />
      );
    } else {
      return <LinearProgress data-testid="liner-progress-testid" />;
    }
  }

  return (
    <div data-testid="customerDetailsComponent-testid">
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          indicatorColor="primary"
          variant="fullWidth"
        >
          <Tab
            className="showDetails"
            label="Account Information"
            icon={<PersonOutlineIcon />}
            data-testid="accountInfo-testid"
          />
          <Tab
            className="showDetails"
            label="Ownership Information"
            icon={<DriveEtaIcon />}
            data-testid="ownershipInfo-testid"
            disabled={!customerDetails?.customerDetail?.isCCDataPresent}
          />
        </Tabs>
      </Paper>
      {checkCustomerDetails(customerDetails)}
    </div>
  );
}
