import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import * as regexPatterns from '../../utilities/regexPatterns'

const useStyles = makeStyles((theme) => ({
    search: {
        paddingLeft: "10px",
        position: "relative",
        borderRadius: "15px",
        backgroundColor: "#fff",
        "&:hover": {
            backgroundColor: "#fff",
        },
        marginRight: theme.spacing(2),
        marginLeft: 2,
        width: "100%",
    },
    inputRoot: {
        color: "inherit",
        display: "flex",
    },
}));


function generateErrorMessage(type, value, regex, flag) {
    if (flag) {
        return regex.test(value) || (value.length === 0) ? "" : `Enter valid ${type}`
    }
    else {
        return regex.test(value) || value.length === 0 ? "" : `${type} is not valid`;
    }
}

function searchButtonDisable(values) {
    const items = values;
    let flag = false;
    for (let i = 0; i < items.length; i++) {
        if (items[i] !== "") {
            flag = true;
            break;
        }
    }
    return flag;
}

function toCamelCase(searchType) {
    if (searchType !== "vin") {
        return searchType.substring(0, 1).toUpperCase() + searchType.substring(1);
    } else {
        return searchType.toUpperCase();
    }
}

function SearchInputField({ searchValue, handleSearch, searchInput, lastName, postalCode, setsearchInput, setLastName, setPostalCode, searchType }) {
    const classes = useStyles();
    const [postalCodeError, setPostalCodeError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [searchInputError, setSearchInputError] = useState("");

    useEffect(() => {
        setsearchInput("");
        setSearchInputError("");
        setLastName("");
        setLastNameError("");
        setPostalCode("");
        setPostalCodeError("");
    }, [searchValue]);

    function handleFirstName(e) {
        const firstName = e.target.value;
        let errorMessage = "";
        errorMessage = generateErrorMessage("first name", firstName, regexPatterns.NAME_REGEX, true);
        setsearchInput(firstName);
        setSearchInputError(errorMessage);
    }

    function handleLastName(e) {
        const lastName = e.target.value;
        let errorMessage = "";
        errorMessage = generateErrorMessage("last name", lastName, regexPatterns.NAME_REGEX, true);
        setLastName(lastName);
        setLastNameError(errorMessage);
    }

    function handlePostalCode(e) {
        const postalCode = e.target.value;
        let errorMessage = "";
        errorMessage = generateErrorMessage("Postal code", postalCode, regexPatterns.POSTAL_CODE_REGEX, true);
        setPostalCode(postalCode);
        setPostalCodeError(errorMessage);
    }

    const handlePhoneNumber = (e) => {
        let errorMessage = "";
        const result = e
          .split("")
          .filter((x) => !isNaN(Number.parseInt(x)))
          .map((x) => Number.parseInt(x));
        if (result.length > 3) {
          result.splice(3, 0, "-");
        }
        if (result.length > 7) {
          result.splice(7, 0, "-");
        }
        const inputValue = result.join("");
        errorMessage = generateErrorMessage("phone number", inputValue, regexPatterns.PHONE_CODE_REGEX, true);
        setsearchInput(inputValue);
        setSearchInputError(errorMessage);
      };

    function validate(event, searchValue) {
        let errorMessage = "";
        const inputValue = event.target.value;
        if (searchType[searchValue] === "email") {
            errorMessage = generateErrorMessage("Email", inputValue, regexPatterns.EMAIL_REGEX)
            setsearchInput(inputValue);
            setSearchInputError(errorMessage);
        } else if (searchType[searchValue] === "vin") {
            errorMessage = generateErrorMessage("VIN", inputValue, regexPatterns.VIN_REGEX);
            setsearchInput(inputValue);
            setSearchInputError(errorMessage);
        } else if (searchType[searchValue] === "businessName") {
            errorMessage = generateErrorMessage("Business", inputValue, regexPatterns.BUSINESS_NAME_REGEX)
            setsearchInput(inputValue);
            setSearchInputError(errorMessage);
        } else if (searchType[searchValue] === "phone") {
            handlePhoneNumber(inputValue);
        }
    }

    const webkitBoxShadow = "0 0 0 1000px white inset";
    const searchIconCommonButton = searchButtonDisable([searchInputError]);
    const searchIconByNameButton = searchButtonDisable([searchInputError, lastNameError, postalCodeError]);
    const searchIconbusinessButton = searchButtonDisable([searchInputError, postalCodeError])

    return (
        <>
            {searchValue === 2 &&
                <form onSubmit={(e) => handleSearch(e)} style={{ width: "100%", display: "flex" }} >
                    <Grid container direction="row" justify="center" alignItems="center" data-testid="nameSearchInputField-testid">
                        <Grid item xs={12} sm={3} className={classes.search}>
                            <TextField className="input" placeholder={"Enter First Name"} classes={{ root: classes.inputRoot, }}
                                inputProps={{ style: { WebkitBoxShadow: webkitBoxShadow }, }} id="firstname" data-testid="firstNameSearchInput-testid" required
                                style={{ borderBottom: "none!important" }} name="firstName" value={searchInput} helperText={searchInputError} onChange={(e) => handleFirstName(e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} className={classes.search}>
                            <TextField className="input" placeholder={"Enter Last Name"} classes={{ root: classes.inputRoot, }}
                                inputProps={{ style: { WebkitBoxShadow: webkitBoxShadow }, }} name="lastName" value={lastName} helperText={lastNameError}
                                onChange={(e) => handleLastName(e)} id="lastname" data-testid="lastNameSearchInput-testid" required
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} className={classes.search}>
                            <TextField className="input" placeholder={"Zipcode"} classes={{ root: classes.inputRoot, }}
                                inputProps={{ style: { WebkitBoxShadow: webkitBoxShadow }, }} name="postalCodes" value={postalCode} helperText={postalCodeError}
                                onChange={(e) => handlePostalCode(e)} data-testid="zipCodeInput-testid" id="postalCode"
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Fab size="small" style={{ backgroundColor: "white", width: "36px", height: "30px", }}
                                aria-label="add" className={classes.margin} type="submit" disabled={searchIconByNameButton} data-testid="searchIconName-testid">
                                <SearchIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                </form>
            }
            {
                searchValue === 3 &&
                <form onSubmit={(e) => handleSearch(e)} style={{ width: "100%", display: "flex" }} >
                    <Grid container direction="row" justify="center" alignItems="center" data-testid='businessSearchInputFields-testid'>
                        <Grid item xs={5} className={classes.search}>
                            <TextField className="input" placeholder={`Search By ${toCamelCase(searchType[searchValue])}`} classes={{ root: classes.inputRoot, }}
                                inputProps={{ style: { WebkitBoxShadow: webkitBoxShadow }, }} name={searchType[searchValue]} value={searchInput} helperText={searchInputError}
                                id="common" onChange={(event) => validate(event, searchValue)} data-testid="businessSearchInput-testid" required />
                        </Grid>
                        <Grid item xs={12} sm={3} className={classes.search}>
                            <TextField className="input" placeholder={"Zipcode"} classes={{ root: classes.inputRoot, }}
                                inputProps={{ style: { WebkitBoxShadow: webkitBoxShadow } }} name="postalCode" value={postalCode} helperText={postalCodeError}
                                onChange={(e) => handlePostalCode(e)} id="postalCode" data-testid="zipCodeInput-testid" required />

                        </Grid>
                        <Grid item xs={1}>
                            <Fab style={{ backgroundColor: "white", width: "36px", height: "30px", }} size="small"
                                aria-label="add" className={classes.margin} type="submit" disabled={searchIconbusinessButton} data-testid="searchIconBusiness-testid">
                                <SearchIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                </form>
            }
            {
                (searchValue === 0 || searchValue === 1 || searchValue === 4) &&
                <form onSubmit={(e) => handleSearch(e)} style={{ width: "100%", display: "flex" }} >
                    <Grid container direction="row" justify="center" alignItems="center" data-testid='commonSearchInputField-testid'>
                        <Grid item xs={5} className={classes.search}>
                            <TextField placeholder={`Search By ${toCamelCase(searchType[searchValue])}`}
                                classes={{ root: classes.inputRoot, }} inputProps={{ style: { WebkitBoxShadow: webkitBoxShadow }, }}
                                name={searchType[searchValue]} value={searchInput} helperText={searchInputError} id="common" onChange={(event) => validate(event, searchValue)}
                                data-testid="commonSearchInput-testid" required />
                        </Grid>
                        <Grid item xs={1}>
                            <Fab style={{ backgroundColor: "white", width: "36px", height: "30px", }}
                                size="small" aria-label="add" className={classes.margin} type="submit" disabled={searchIconCommonButton} data-testid="searchIconCommon-testid">
                                <SearchIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                </form>
            }
        </>
    )
}

export default SearchInputField;
