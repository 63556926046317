import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import ChangeEmail from "./ChangeEmail";
import ResetPasswordLink from "./ResetPasswordLink";
import ResetTemporaryPassword from "./ResetTemporaryPassword";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import timestampToDate from "timestamp-to-date";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { CustomerContext } from "../../ContextApi/CustomersContext";
import ChangePhoneNumber from "./ChangePhoneNumber";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  paddingAcceptButtonNormal: {
    [theme.breakpoints.up("md")]: { borderLeft: "10px solid #f4f6f8", paddingLeft: "10px", },
  },
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 30,
  height: 32,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('/call-1.png')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 31,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '90%',
      height: '90%',
      left: 1,
      top: 1,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('/arroba-2.png')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

function userAddress(userDetails) {
  if (
    userDetails.address.addressLine1 === null &&
    userDetails.address.city === null &&
    userDetails.address.state === null &&
    userDetails.address.postalCode === null
  ) {
    return <b>-</b>;
  } else {
    return (
      <b>
        {userDetails.address.addressLine1 === null ? (
          <b></b>
        ) : (
          <b>
            {userDetails.address.addressLine1} {", "}
          </b>
        )}

        {userDetails.address.city === null ? (
          <b></b>
        ) : (
          <b>
            {userDetails.address.city} {", "}
          </b>
        )}

        {userDetails.address.state === null ? (
          <b></b>
        ) : (
          <b>
            {userDetails.address.state} {", "}
          </b>
        )}

        {userDetails.address.postalCode === null ? (
          <b></b>
        ) : (
          <b>{userDetails.address.postalCode}</b>
        )}
      </b>
    );
  }
}

function userFRPhoneNumber(telephoneNumber) {
  if (!telephoneNumber || telephoneNumber === null) {
    return <b>-</b>
  } else {
    return (
      <>
        <b>{`${telephoneNumber.substring(0, 3)}-`}</b>
        <b>{`${telephoneNumber.substring(3, 6)}-`}</b>
        <b>{`${telephoneNumber.substring(6)}`}</b>
      </>
    );
  }
}

function userPrimaryPhoneNumber(phoneNumber) {
  if (
    phoneNumber.areaCode === null ||
    phoneNumber.phoneNo === null
  ) {
    return <b>-</b>;
  } else {
    return (
      <>
        <b>{`${phoneNumber.areaCode}-`}</b>
        <b>{`${phoneNumber.phoneNo.substring(
          0,
          3
        )}-${phoneNumber.phoneNo.substring(3)}`}</b>
      </>
    );
  }
}

function userAlternatePhoneNumber(userDetails) {
  if (
    userDetails.alternateNumber.areaCode === null ||
    userDetails.alternateNumber.phoneNo === null
  ) {
    return <b>-</b>;
  } else {
    return (
      <>
        <b>{`${userDetails.alternateNumber.areaCode}-`}</b>
        <b>{`${userDetails.alternateNumber.phoneNo.substring(
          0,
          3
        )}-${userDetails.alternateNumber.phoneNo.substring(3)}`}</b>
      </>
    );
  }
}

function userDetailGeneration(detail){
  return detail === null ? <b>-</b> : <b>{detail}</b>
}

export default function AccountInfoComponent(props) {
  const classes = useStyles();
  const { REACT_APP_API_URL } = process.env;
  const [, , tokenAndRolesCapture, [, setcustomerDetails]] = useContext(CustomerContext);
  const { userDetails } = props;
  const isCCDataPresent = userDetails.isCCDataPresent;
  const [loading, setLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(userDetails.status === "NO" ? false : true);
  const [loginEmailId, setLoginEmailId] = useState(userDetails.accountEmailAddress);
  const [phoneNumber, setPhoneNumber] = useState(userDetails.telephoneNumber);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [activateUserResponseCode, setActivateUserResponseCode] = useState(true);
  const [activateUserErrorResponse, setActivateUserErrorResponse] = useState("");
  const [checked, setChecked] = React.useState(false);
  const backgroundColor = "#f4f6f8";
  const isButtonDisabled = emailVerified || !isCCDataPresent;
  const activateUserButtonClassName = isButtonDisabled ? clsx(classes.buttonStyle, "disableButton") : clsx(classes.buttonStyle, "buttonAccept");
  const checkEmailVerified = emailVerified ? "YES" : "NO";
  const activateUserData = { guid: userDetails.guid, opType: "new", };
  const handleResponse = (response) => {
    if (response.data.code === 200) {
      setEmailVerified(true);
      setcustomerDetails({customerDetail: {...userDetails, status: "YES" }})
      setActivateUserResponseCode(true);
      setTimeout(()=>setOpenAlert(false),5000)
      setOpenAlert(true);
      return;
    }
    setEmailVerified(false);
    setActivateUserResponseCode(false);
    setActivateUserErrorResponse("Cannot activate your account at this moment. Please try again later.");
    setOpenAlert(true);
  }
  const activateUserApiCall = (idToken) => {
    setLoading(true);
    const config = { headers: { "Content-type": "application/json", Authorization: `Bearer ${idToken}`, }, };
    axios.post(`${REACT_APP_API_URL}/gap/activate/user`, activateUserData, config)
      .then((response) => {
        setLoading(false);
        handleResponse(response);
      })
      .catch((error) => {
        setOpenAlert(true);
        setLoading(false);
        setActivateUserResponseCode(false);
        setActivateUserErrorResponse("Cannot activate your account at this moment. Please try again later."); });
  };
  async function handleActivateUser() {
    tokenAndRolesCapture().then((tokenResponse) => activateUserApiCall(tokenResponse.idToken));
  }
  const handleChange = () => setChecked((prev) => !prev);
  return (
    <div data-testid="AccountInfo-testid">
      <div style={{ backgroundColor: backgroundColor, padding: "10px", display: isCCDataPresent ? "none" : "block"}}>
        <Alert severity="error">The user is not found in Customer Central. All functionality is disabled in GAP for this user. Please try again later.</Alert>
      </div>
      <div style={{ backgroundColor: backgroundColor, padding: "10px" }}>
        <div style={{ backgroundColor: "white", padding: "10px" }}>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Grid item xs={6} md={3}>
              <p><b>Title:</b> &nbsp;{userDetailGeneration(userDetails.title)}</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <p><b>First Name:</b> &nbsp;{userDetailGeneration(userDetails.firstName)}</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Middle Name:</b> &nbsp;{userDetailGeneration(userDetails.middleName)}</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Last Name:</b> &nbsp;{userDetailGeneration(userDetails.lastName)}</p>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Grid item xs={6} md={3}>
              <p><b>Business:</b> &nbsp;{userDetailGeneration(userDetails.business)}</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <p><b>Address:</b> &nbsp;{userAddress(userDetails)}</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <p><PhoneIcon style={{ paddingTop: "12px" }} size="small" /> <b>Primary Number (Customer Central):</b> &nbsp;{userPrimaryPhoneNumber(userDetails.primaryNumber)}</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <p> <PhoneIcon style={{ paddingTop: "12px" }} size="small" /> <b>Alternate Number:</b> &nbsp;{userAlternatePhoneNumber(userDetails)}</p>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Grid item xs={6} md={6}>
              <p>
                <EmailIcon style={{ paddingTop: "12px" }} size="small" />
                <b>Communication Email Address:</b> &nbsp;{userDetails.communicationEmailAddress === null ? <b>-</b> : <b>{loginEmailId}</b>}
              </p>
            </Grid>
            <Grid item xs={6} md={6}>
              <p>
                <EmailIcon style={{ paddingTop: "12px" }} size="small" />
                <b>Account Email Address:</b> &nbsp;{userDetails.accountEmailAddress === null ? <b>-</b> : <b>{loginEmailId}</b>}
              </p>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ backgroundColor: backgroundColor, padding: "10px" }}>
        <div style={{ backgroundColor: "white" }}>
          <Grid container direction="row" justify="space-between" alignItems="stretch">
            <Grid container item md={6} style={{ paddingLeft: "10px" }}>
              <Grid item xs={12}>
                <p><b>Login ID:</b> &nbsp;{userDetails.loginId === null ? <b>-</b> : <b>{loginEmailId}</b>}</p>
              </Grid>
              <Grid item xs={12}>
                <p><b>GUID:</b> &nbsp;{userDetailGeneration(userDetails.guid)}</p>
              </Grid>
              <Grid item xs={12}>
                <p> <b>Customer ID:</b> &nbsp;{userDetailGeneration(userDetails.customerId)}</p>
              </Grid>
              <Grid item xs={12}>
                <p> <b>Phone Number(ForgeRock):</b> &nbsp;{userFRPhoneNumber(phoneNumber)}</p>
              </Grid>
            </Grid>
            <Grid container item md={6} className={classes.paddingAcceptButtonNormal}>
              <Collapse in={openAlert}> {activateUserResponseCode ? ( <div>
                    <Alert severity="success"
                      action={
                        <IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpenAlert(false);}}>
                          <CloseIcon fontSize="inherit" />
                        </IconButton>}>User account has been successfully activated
                    </Alert>
                  </div>
                ) : (
                  <div>
                    <Alert severity="error"
                      action={
                        <IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpenAlert(false);}}>
                          <CloseIcon fontSize="inherit" />
                        </IconButton>}>{activateUserErrorResponse}
                    </Alert>
                  </div>)}
              </Collapse>
              <Grid item xs={12}>
                <p>
                  <b>Email Verified:</b> &nbsp;
                  {userDetails.status === null ? <b>-</b> : <b>{checkEmailVerified}</b>}
                  <Button variant="outlined" color="primary" disabled={isButtonDisabled} className={activateUserButtonClassName}
                  style={{ fontWeight: "bolder", marginLeft: "40px" }} onClick={handleActivateUser} data-testid="activateUserButton-testid">Activate User</Button>
                  <span style={{ paddingLeft: "50px", paddingTop: "20px" }}>{loading ? <CircularProgress /> : null}</span>
                </p>
              </Grid>
              <Grid item xs={12}>
                <p><b>Registration Source:</b> &nbsp;{userDetailGeneration(userDetails.registrationSource)}</p>
              </Grid>
              <Grid item xs={12}>
                <p><b>Creation Date:</b> &nbsp;{userDetails.creationDate === null ? <b>-</b> : <b>{timestampToDate(userDetails.creationDate,"MM/dd/yyyy HH:mm:ss" )}</b> }</p>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ backgroundColor: backgroundColor, padding: "10px" }}>
        <div style={{ backgroundColor: "white" }}>
        <div style={{padding: "15px 0px 0px 15px" }} item xs={10}>
         <b style={{ fontSize: "1rem"}}>Change</b> &nbsp;
        <FormControlLabel className="label-font checked checkBox switch-root"
         control={<MaterialUISwitch sx={{ m: 1 }} checked={checked} onChange={handleChange} inputProps={{"data-testid": "switch-testid"}} />}
        />
        </div>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" style={{ padding: "10px" }}>
            <Grid item xs={12}>
              {!checked ? (<ChangeEmail setEmailVerified={setEmailVerified} guid={userDetails.guid}
              loginEmailId={loginEmailId} setLoginEmailId={setLoginEmailId} data-testid="changeEmail-testid" isCCDataPresent={isCCDataPresent} />
              ):
              (<ChangePhoneNumber emailVerified={emailVerified} guid={userDetails.guid}
                phoneNumber={phoneNumber} setPhoneNumberChanged={setPhoneNumber} isCCDataPresent={isCCDataPresent} />
              )}
              </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ backgroundColor: backgroundColor, padding: "10px" }}>
        <div style={{ backgroundColor: "white" }}>
          <Grid container direction="row"justify="flex-start" alignItems="flex-start" style={{ padding: "10px" }}>
            <Grid item xs={12} md={12}>
              <ResetPasswordLink emailVerified={emailVerified} email={loginEmailId} data-testid="resetPasswordLink-testid" isCCDataPresent={isCCDataPresent} />
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ backgroundColor: backgroundColor, padding: "10px" }}>
        <div style={{ backgroundColor: "white" }}>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" style={{ padding: "10px" }}>
            <Grid item xs={12} md={12}>
              <ResetTemporaryPassword emailVerified={emailVerified} guid={userDetails.guid} data-testid="resetTemporaryPassword-testid" isCCDataPresent={isCCDataPresent} />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
