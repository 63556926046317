import React from "react";
import Modal from "@material-ui/core/Modal";
import { getModalStyle,modalStyles } from '../../utilities/modal';
import * as dependency from '../../utilities/sortingTableImports';
import {message} from '../../utilities/message';

export const GenericModal = ({ open,handleClose,handleOk,title,body, okButtonText = message.okay }) => {

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
      <div style={getModalStyle()} className={modalStyles().modalPaper} data-testid="modal-testid">
      <div style={{ textAlign: "center" }}><b>{title}</b></div>
      <p id="simple-modal-description">{body}</p>
      <dependency.Grid container direction="row" justify="space-between" alignItems="center" >
        <dependency.Grid item>
          <dependency.Button size="medium" variant="outlined" className="buttonAccept"
          onClick={() => handleOk()}  data-testid='modal-okay-button-testid'>
            {okButtonText}
          </dependency.Button>
        </dependency.Grid>
        <dependency.Grid item>
          <dependency.Button size="medium" variant="outlined" className="buttonAccept"
           onClick={handleClose} data-testid='modal-cancel-button-testid'>{message.cancel}
           </dependency.Button>
        </dependency.Grid>
      </dependency.Grid>
    </div>
    </Modal>
  );
};
