import React, { useState, useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import Checkbox from "@material-ui/core/Checkbox";
import { CustomerContext } from "../../ContextApi/CustomersContext";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { PASSWORD_REGEX } from '../../utilities/regexPatterns';

const myStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  buttonStyle: {
    [theme.breakpoints.down("md")]: {
      padding: "1px!important",
      fontSize: "9px!important",
    },
  },
}));

function resetTemporaryPasswordInstructions() {
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid item>
        <b>
          <p>Instructions for resetting the temporary password:</p>
          <ol type="1">
            <li>
              Password length must be minimum of 8 and maximum of 16
              characters
            </li>
            <li>
              Requires 3 out of the following 4 options:
              <ol type="a">
                <li>Lowercase characters</li>
                <li>Uppercase characters</li>
                <li>Numbers</li>
                <li>Special characters</li>
              </ol>
            </li>
            <li>Password is case sensitive</li>
          </ol>
        </b>
      </Grid>
    </Grid>
  )
}

function displayPassword(showPassword) {
  return showPassword ? <Visibility /> : <VisibilityOff />;
}

function loadingCheck(loading) {
  return loading ? <CircularProgress /> : null
}

function displayAlert(emailVerified, props) {
  return !emailVerified && props.isCCDataPresent ? (<Alert severity="error"> Please Activate user's account before resetting temp password. </Alert>) : null
}

function passwordtype(showPassword) {
  return showPassword ? "text" : "password"
}

function buttonStyle(disableButton, classes) {
  return disableButton ? clsx(classes.buttonStyle, "disableButton") : clsx(classes.buttonStyle, "buttonAccept")
}

function ResetTemporaryPassword(props) {
  const { REACT_APP_API_URL } = process.env;
  const classes = myStyles();
  const [, , tokenAndRolesCapture] = useContext(CustomerContext);
  const [open, setOpen] = useState(false);
  const [checkedState, setCheckedState] = useState(false);
  const [temporaryPassword, setTemporaryPassword] = useState("");
  const [showPassword, setshowPassword] = useState(false);
  const [temporaryPasswordError, setTemporaryPasswordError] = useState("");
  const [resetTemporaryPasswordError,setResetTemporaryPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const emailVerified = props.emailVerified;
  let disableButton = false;
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (emailVerified === false || checkedState === false || temporaryPassword === "" || temporaryPasswordError !== "" || !props.isCCDataPresent) {
    disableButton = true;
  }
  const handleChange = (event) => {
    setCheckedState(!checkedState);
  };
  const data = {
    guid: props.guid,
    newPassword: temporaryPassword,
  };

  const handleResetTemporaryPassword = async () => {
    setLoading(true);
    const tokenResponse = await tokenAndRolesCapture();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${tokenResponse.idToken}`,
      },
    };
    axios.post(`${REACT_APP_API_URL}/gap/reset/temp-password`, data, config)
      .then((response) => {
        setLoading(false);
        if(response.data.code === 200) {
          setResetTemporaryPasswordError("");
          setTimeout(()=>setOpen(false),5000)
          setOpen(true);
          setTemporaryPassword("");
        } else if(response.data.description.includes("Invalid new password")){
          setOpen(true);
         setResetTemporaryPasswordError("Invalid new password – The entered password must not match with the recently used password, please try again with a different password.");
         setTemporaryPassword("");
        }
        else{
          setOpen(true);
          setResetTemporaryPasswordError("Cannot reset your temporary password at this moment. Please try again later.");
          setTemporaryPassword("");
        }
      })
      .catch((error) => {
        setResetTemporaryPasswordError("Cannot reset your temporary password at this moment. Please try again later.");
        setLoading(false);
        setTemporaryPassword("");
      });
  };
  function passwordValidation(event) {
    let errorMessage = "";
    const inputValue = event.target.value;
    const passwordRegex = PASSWORD_REGEX;

    errorMessage = passwordRegex.test(inputValue) || inputValue.length === 0 ? "" : "Password is not valid";
    setTemporaryPassword(inputValue);
    setTemporaryPasswordError(errorMessage);
  }

  return (
    <div data-testid="resetTemporaryPassword-testid">
      <Paper style={{ marginTop: "25px", padding: "15px" }}>
        <h3>Reset Temporary Password</h3>
        <Collapse in={open}>{!resetTemporaryPasswordError ? (
          <Alert severity="success"
            action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpen(false); }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>}
          > Temporary Password Reset successful.
          </Alert>
        ) : (
          <Alert severity="error"
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpen(false); }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          > {resetTemporaryPasswordError}
          </Alert>
        )}
        </Collapse>
        <br/>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" >
              <InputLabel htmlFor="outlined-adornment-password"> Reset Temporary Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={passwordtype(showPassword)}
                name="temporaryPassword"
                value={temporaryPassword}
                onChange={(e) => passwordValidation(e)}
                label="Reset Temporary Password"
                disabled={!props.isCCDataPresent || !emailVerified}
                autoComplete="off"
                data-testid="resetTemporaryPasswordInputField-testid"
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end"
                      data-testid='showPasswordIcon-testid' >
                      {displayPassword(showPassword)}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="filled-weight-helper-text">{temporaryPasswordError} </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox checked={checkedState} onChange={handleChange} name="checkedState" color="primary" disabled={!props.isCCDataPresent || !emailVerified} />}
              data-testid="resetTemporaryPasswordCheckbox-testid" label={<b>I have verified this User Identity</b>}
            />
          </Grid>
          <Grid item>{loadingCheck(loading)}</Grid>
          <Grid item>
            <Button color="primary"
              className={buttonStyle(disableButton, classes)}
              style={{ fontWeight: "bolder" }} disabled={disableButton} variant="outlined" onClick={handleResetTemporaryPassword}
              data-testid="resetTemporaryPasswordButton-testid"
            > Reset</Button>
          </Grid>
        </Grid>
        {displayAlert(emailVerified, props)}
        {resetTemporaryPasswordInstructions()}
      </Paper>
    </div>
  );
}

export default ResetTemporaryPassword;
