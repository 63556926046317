import React, { useState, useContext, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CustomerContext } from "../../ContextApi/CustomersContext";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EnhancedTableHead, {
  myStyles,
  getComparator,
  stableSort,
} from "../../utilities/SortingTable";

import * as dependency from "../../utilities/sortingTableImports";
import { message } from "../../utilities/message";
import { Checkbox } from "@material-ui/core";
import axios from "axios";
import { GenericModal } from "../GapModal/generic-modal";

function createData(
  shID,
  serviceDate,
  serviceType,
  mileage,
  customerId,
  serviceDescription,
  isChecked
) {
  return {
    shID,
    serviceDate,
    serviceType,
    mileage,
    customerId,
    serviceDescription,
    isChecked
  };
}

export const dateConversion = (dateValue) => {
  const newDate = Object.prototype.toString.call(dateValue) === '[object Date]' ? dateValue : new Date(dateValue);
  let day = newDate?.getDate();
  let month = newDate?.getMonth() + 1;
  day = day?.toString()?.length < 2 ? `0${day}` : day;
  month = month?.toString()?.length < 2 ? `0${month}` : month;
  const fullyear = newDate?.getFullYear()?.toString();
  return `${month?.toString()}-${day?.toString()}-${fullyear}`;
};

function getServiceRecordsError(deletedServiceHistoryDetails, manualServiceVinDetailsError) {
  if(manualServiceVinDetailsError) {
    return <Alert severity="error">{manualServiceVinDetailsError}</Alert>;
  }
  else {
    return deletedServiceHistoryDetails && deletedServiceHistoryDetails.length === 0 ?
     (<Alert severity="info">{message.deleteAlert}</Alert>) : null;
  }
}

function sortOrderCheck(orderBy, property, order) {
  const isAsc = orderBy === property && order === "asc";
  return isAsc ? "desc" : "asc";
}

const showWarningBanner = (deletedServiceHistoryDetails) => {
  if(deletedServiceHistoryDetails.length > 0) {
    return (
    <Alert severity="warning" >
      <b>{message.restoreDelete.body}*</b>
      <br /><br />
      <b>{message.restoreDelete.additionalNote1}</b>
      </Alert>
    );
  }
  return null;
}

const isLoading = (loading, vinDetailsLoading) => loading || vinDetailsLoading;

const isRestoreButtonDisabled = (isLoading, recordsNotSelected) => isLoading || recordsNotSelected;

export default function DeletedServiceListingTable(props) {
  const ROLLBACK_FAILED = "Rollback failed!"
  const { REACT_APP_API_URL } = process.env;
  const [, , tokenAndRolesCapture] = useContext(CustomerContext);
  const classes = myStyles();
  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openAlert, setOpenAlert] = useState(false);
  const areRecordsSelected = rows.some(record => record.isChecked);
  const [rollbackError, setRollbackError] = useState("");

  useEffect(() => {
    setPage(0);
    setRows(
      props.deletedServiceHistoryDetails.map((serviceRecord) =>
        createData(
            serviceRecord.serviceHistoryId,
            serviceRecord.serviceDate,
            serviceRecord.serviceType,
            parseInt(serviceRecord.mileage),
            parseInt(serviceRecord.customerId),
            serviceRecord.serviceDesc,
            false
        )
      )
    );
  }, [props.deletedServiceHistoryDetails.length]);

  const requestSort = (event, property) => {
    const sortOrder = sortOrderCheck(orderBy, property, order);
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleClose = () => setOpenModal(false);

  const handleRestoreServiceHistoryConfirmation = () => setOpenModal(!openModal);

  const rollbackServiceData = () => {
    const selectedRecords = rows.filter(
      record => record.isChecked
    ).map(
      record => {
        return props.deletedServiceHistoryDetails.find(deletedRecord => deletedRecord.serviceHistoryId === record.shID);
      }
    );
    return {
      vin: props.vin,
      guid: props.guid,
      customerId: props.customerId,
      brand: props.brand,
      transaction: "rollback",
      ownerShipInfo: selectedRecords
    };
  }

  const handleRestoreServiceHistoryHelper = async (token) => {
    try {
      setLoading(true);
      const config = { headers: { "Content-type": "application/json", Authorization: `Bearer ${token}`, }, };
      const rollbackVshApiResponse = await axios.put(`${REACT_APP_API_URL}/gap/update/vsh`, rollbackServiceData(), config);
      setLoading(false);
      if (rollbackVshApiResponse.data.code === 200) {
        setRollbackError("");
        props.makeVSHCall().then(() => {
          console.log("DeleteServiceListingTable :: makeVSHCall");
          setOpenAlert(true);
        }).catch(() => {
          console.log("DeleteServiceListingTable :: makeVSHCall :: failed");
        });
        return;
      }
      setRollbackError(ROLLBACK_FAILED);
    } catch(error) {
      setRollbackError(ROLLBACK_FAILED);
      setLoading(false);
    }
  }

  const handleRestoreServiceHistory = () => {
    setOpenModal(false);
    tokenAndRolesCapture().then((tokenResponse) => {
      handleRestoreServiceHistoryHelper(tokenResponse.idToken);
    });
  };

  const handleOnSelect = (isChecked, recordId) => {
    setRows(prevState => {
      return prevState.map(record => record.shID  !== recordId ? record : {...record, isChecked})
    });
  }

  const loadCloseButton = () => {
    return (<IconButton aria-label="close" color="inherit" size="small" onClick={() => setOpenAlert(false) }>
      <CloseIcon fontSize="inherit" />
    </IconButton>);
  };

  return (
    <div
      className={classes.root}
      data-testid="deleteServiceListingTable-testid"
    >
      <Paper className={classes.paper}>
        <dependency.Grid container spacing={1} direction="row" justify="space-between" alignItems="baseline" style={{ padding: "20px" }}>
          <dependency.Grid item>
            <h3>Deleted Service History Details</h3>
          </dependency.Grid>
          <dependency.Grid item>
            {
            isLoading(loading, props.vinDetailsLoading) ? <CircularProgress data-testid="restoreServiceLoader-testid" /> : null
            }
          </dependency.Grid>
          <dependency.Grid item>
            <dependency.Button
              className={isRestoreButtonDisabled(isLoading(loading, props.vinDetailsLoading), !areRecordsSelected)
                ? "disableButton" : "buttonAccept"}
              variant="outlined" color="primary" style={{ fontWeight: "bolder" }}
              disabled={isRestoreButtonDisabled(isLoading(loading, props.vinDetailsLoading), !areRecordsSelected)}
              onClick={handleRestoreServiceHistoryConfirmation}
              data-testid="restoreServiceButton-testid">
                Restore Selected Deleted Records
            </dependency.Button>
            <GenericModal open={openModal} handleClose={handleClose} handleOk={handleRestoreServiceHistory}
            title={message.restoreDelete.title} body={message.restoreDelete.body} okButtonText={message.restore} />
          </dependency.Grid>
          <dependency.Grid container direction="column">
            <dependency.Grid item>
              {
                !props.manualServiceVinDetailsError &&
                <Collapse in={openAlert} style={{ paddingBottom: "20px" }}>
                  {
                    rollbackError ?
                    <Alert severity="error" action={loadCloseButton()}
                    >{rollbackError}</Alert>
                    :
                    <Alert severity="success" action={loadCloseButton()}
                    >Service History Restored Successfully</Alert>
                  }
                </Collapse>
              }
            </dependency.Grid>
            <dependency.Grid item >
              {getServiceRecordsError(props.deletedServiceHistoryDetails, props.manualServiceVinDetailsError)}
              <dependency.TableContainer>
                <dependency.Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                  <EnhancedTableHead classes={classes} order={order} orderBy={orderBy}
                  onRequestSort={requestSort} rowCount={rows.length} headCells={props.headerCells}
                  restoreDeletedServiceHistoryTableHead={true} />
                  <dependency.TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <dependency.TableRow tabIndex={-1} key={record.shID} className={
                          record.customerId === parseInt(props.customerId) ? "rowHighlightDeleted" : null
                          }>
                          <dependency.TableCell align="center">
                            <Checkbox
                             onChange={(_, checked) => handleOnSelect(checked, record.shID)}
                             disabled={parseInt(props.customerId) !== record.customerId}
                             checked={record.isChecked} />
                          </dependency.TableCell>
                          <dependency.TableCell component="th" id={labelId} scope="row" padding="none" align="center">{dateConversion(record.serviceDate)}
                          </dependency.TableCell>
                          <dependency.TableCell align="center">Self-Reported</dependency.TableCell>
                          <dependency.TableCell align="center"> {record.mileage}</dependency.TableCell>

                          <dependency.TableCell align="center"> {record.customerId}</dependency.TableCell>
                          <dependency.TableCell align="center">{record.serviceDescription}</dependency.TableCell>
                        </dependency.TableRow>
                      );
                    })}
                    {emptyRows > 0 && (<dependency.TableRow style={{ height: 53 * emptyRows }}><dependency.TableCell colSpan={6} /></dependency.TableRow>)}
                  </dependency.TableBody>
                </dependency.Table>
              </dependency.TableContainer>
              <dependency.TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </dependency.Grid>
            <dependency.Grid item>
              {showWarningBanner(props.deletedServiceHistoryDetails)}
            </dependency.Grid>
          </dependency.Grid>
        </dependency.Grid>
      </Paper>
    </div>
  );
}
