import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  padding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "15px",
      paddingRight: "80px",
      paddingTop: "50px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingTop: "30px",
    },
  },
  marginNew: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "48px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "48px",
    },
  },
}));

function DisclaimerPageBody() {
  const classes = useStyles();

  return (
    <div
      style={{ backgroundColor: "white" }}
      className={classes.marginNew}
      data-testid="disclaimerPageBody-testid"
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        className={classes.padding}
      >
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <div className={classes.paper}>
            <img src="/toyota-1.png" alt="toyota" />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <div className={classes.paper}>
            <img src="/lexus-1.png" alt="lexus" />
          </div>
        </Grid>
      </Grid>

      <div style={{ fontFamily: "Toyota-Light" }} className={classes.padding}>
        <p>
          The application that you are using accesses data from Customer Central
          Application. The data in Customer Central Application is considered,
          and is to be treated as, CONFIDENTIAL and PRIVILEGED INFORMATION.
        </p>
        <p>
          To Access the Guest Admin Portal, you must AGREE to the following:
        </p>
        <ol type="1">
          <li>
            You have no personal or proprietary interest in any of the data.
          </li>
          <li>
            You will treat the GAP data as Confidential and Privileged
            Information.
          </li>
          <li>
            You will not disclose nor publish the nature or content of any data
            to any third party or individual unless it is a stated part of your
            job, within company procedures.
          </li>
          <li>
            You will not allow any other person access to the GAP application
            using your personal ID/Password.
          </li>
          <li>
            You will notify your Supervisor/Manager when access to the
            Confidential and Privileged Information is no longer needed.
          </li>
          <li>
            You will ASK YOUR SUPERVISOR/MANAGER if you have any questions on
            how to treat Confidential and Privileged Information.
          </li>
          <li>
            You understand that these terms supplement rather than supersede the
            terms of any other confidentiality agreement(s) that may exist.
          </li>
        </ol>
        By checking "I Agree" you are agreeing to abide by all provisions listed
        above. If you do not check "I Agree" you will not be allowed to use this
        application.
        <br />
      </div>
    </div>
  );
}

export default DisclaimerPageBody;
