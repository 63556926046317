import React from "react";
import TableCell from "@material-ui/core/TableCell";

const CustomerDataComponent = (props) =>
  props.keys.map((key, index) => {
    return (
      <TableCell
        align="center"
        className="customer-search-table"
        key={index}
        data-testid={`customerDataComponent${index}-testid`}
      >
        {props.row[key] === "" || props.row[key] === null
          ? "-"
          : props.row[key]}
      </TableCell>
    );
  });

export default CustomerDataComponent;
