import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { CardMedia } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}))

function Cookbook({ open, handleClose, gifPath }) {
    const cookbookClasses = useStyles();
    return (
        <Backdrop
            className={cookbookClasses.backdrop}
            open={open}
            onClick={() => handleClose()}
        >
            <CardMedia
                component="video"
                width="720"
                height="570"
                image={gifPath}
                controls
                autoPlay
                data-testid="cookbookGif-testid"
            />
        </Backdrop>
    )
}

export default Cookbook;
