import React from "react";
import CustomerListingComponent from "./CustomerListingComponent";
import * as heads from '../../utilities/customerTableHeads';

const keys = ["email", "status", "businessName", "ownershipType"];
const BusinessHeadCells = [
  heads.EMAIL_HEAD_CELL,
  heads.STATUS_HEAD_CELL,
  heads.BUSINESSNAME_HEAD_CELL,
  heads.ACCOUNTTYPE_HEAD_CELL,
  heads.ACCOUNTCREATEDDATE_HEAD_CELL
];

export default function CustomerBusinessListingComponent() {
  function createData({ serialNo, email, status, businessName, ownershipType, accountCreatedDate }, action) {
    return {
      serialNo,
      email,
      status,
      businessName,
      ownershipType,
      accountCreatedDate,
      action,
    };
  }
  return (
    <div data-testid="customerBusinessListingComponent-testid">
      <CustomerListingComponent
        keys={keys}
        headCells={BusinessHeadCells}
        createData={createData}
        searchType="business"
      />
    </div>
  );
}
