import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export const myStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 750,
  },
  buttonStyle: {
    [theme.breakpoints.down("md")]: {
      padding: "20px!important",
      fontSize: "9px!important",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export function getEpochTime(value) {
  return new Date(value).getTime();
}

export function determineDateValue(type, value) {
  switch(type) {
    case "serviceDate":
      return getEpochTime(value);
    case "accountCreatedDate":
      return parseInt(value);
    case "effectiveDT":
      return value === "-" ? 0 : getEpochTime(value);
    case "expirationDT":
      return value === "-" ? 0 : getEpochTime(value);
    default:
      return value;
  }
}

export function descendingComparator(a, b, orderBy) {
  const value1 = determineDateValue(orderBy, a[orderBy]);
  const value2 = determineDateValue(orderBy, b[orderBy]);
  if (value2 < value1) {
    return -1;
  } else if (value2 > value1) {
    return 1;
  } else {
    return 0;
  }
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const getSortType = order === "desc" ? "sorted descending" : "sorted ascending";

  return (
    <TableHead data-testid="enhancedTableHead-testid">
      <TableRow>
        {props.customerTableHead && (
          <TableCell align="center" className="tableHeaderRow ">
            S.No
          </TableCell>
        )}
        {props.restoreDeletedServiceHistoryTableHead && (
          <TableCell align="center" className="tableHeaderRow ">
            Status
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            className="tableHeaderRow "
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            align="center"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? <span className={classes.visuallyHidden}>{getSortType}</span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {props.customerTableHead && (
          <TableCell align="center" className="tableHeaderRow ">
            Action
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
};
