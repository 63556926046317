import React, { useEffect, useState, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import VinListingTable from "./VinListingTable";
import ManualSeriveListingTable from "./ManualServiceListingTable";
import axios from "axios";
import { CircularProgress, LinearProgress, Tab, Tabs,
  Grid, Paper, FormControl, NativeSelect, InputBase } from "@material-ui/core";
import { CustomerContext } from "../../ContextApi/CustomersContext";
import DeletedServiceListingTable from "./DeletedServiceListingTable";

const headerCells = [
  {
    id: "serviceDate",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "serviceType",
    numeric: false,
    disablePadding: false,
    label: "Service Type",
  },
  { id: "mileage", numeric: true, disablePadding: false, label: "Mileage" },
  {
    id: "customerId",
    numeric: true,
    disablePadding: false,
    label: "Customer ID",
  },
  {
    id: "serviceDescription",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
];

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: "200px",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 15,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function checkErrorProgress(ownershipInfoDataError) {
  if (ownershipInfoDataError) {
    return (
      <h3 style={{ textAlign: "center" }}>
        {ownershipInfoDataError}
      </h3>
    );
  } else {
    return <LinearProgress />;
  }
}

function deleteVinLoader(deleteVinLoading) {
  return deleteVinLoading ? <CircularProgress /> : null;
}

function ownershipInfoDataCheck(ownershipInfoDetails) {
  return ownershipInfoDetails.length > 0;
}

function filterOwnershipInfoResponse(ownershipInfoResponse) {
  const detailObjects = [];
  const dropdownVin = [];
  ownershipInfoResponse.forEach((detail) => {
    if (detail.vin === ownershipInfoResponse[0].vin) {
      detailObjects.push(detail);
    }
    if (!dropdownVin.includes(detail.vin)) {
      dropdownVin.push(detail.vin)
    }
  });
  return {
    "detailObjects": detailObjects,
    "dropdownVin": dropdownVin
  }
}

function ownershipInfoResponseStatusCheck(code, description) {
  if (code === 200) {
    return description;
  } else {
    return "Cannot fetch ownership info details at this moment. Please try again later."
  }
}

const OwnershipInfoComponent = (props) => {
  const { REACT_APP_API_URL } = process.env;
  const classes = useStyles();
  const [, , tokenAndRolesCapture] = useContext(CustomerContext);
  const [ownershipInfoDetails, setOwnershipInfoDetails] = useState([]);
  const [vin, setVin] = useState("");
  const [brand, setBrand]= useState("");
  const [vinTableDetails, setVinTableDetails] = useState([]);
  const [manualServiceVinDetails, setManualServiceVinDetails] = useState([]);
  const [ownershipInfoDataError, setownershipInfoDataError] = useState("");
  const [manualServiceVinDetailsError, setmanualServiceVinDetailsError] =
    useState("");
  const [deleteVinLoading, setDeleteVinLoading] = useState(false);
  const [vinDetailsLoading, setvinDetailsLoading] = useState(false);
  const [dropdownVin, setDropdownVin] = useState([])
  const [tabNumber, setTabNumber] = useState(0);
  const availableServiceHistoryDetails = [];
  const deletedServiceHistoryDetails = [];
  manualServiceVinDetails.forEach(record => {
    record.deleteFlag ? deletedServiceHistoryDetails.push(record) : availableServiceHistoryDetails.push(record)
  });

  function handleSelectChange(e) {
    setVin(e.target.value);
    const detailObject = [];
    ownershipInfoDetails.forEach((detail) => {
      if (detail.vin === e.target.value) {
        detailObject.push(detail);
        setBrand(detailObject[0].brand.charAt(0));
      }
    });
    setVinTableDetails(detailObject);
  }

  function OwnershipInfoRes(response) {
    if (response.data.results) {
      setOwnershipInfoDetails(response.data.results.ownershipInfoResponse);
      setBrand(response.data.results.ownershipInfoResponse[0].brand.charAt(0));
      setVin(response.data.results.ownershipInfoResponse[0].vin);
      const temp = filterOwnershipInfoResponse(response.data.results.ownershipInfoResponse);
      setDropdownVin(temp.dropdownVin);
      setVinTableDetails(temp.detailObjects);
      return;
    }
    const errorMessege = ownershipInfoResponseStatusCheck(response.data.code, response.data.description);
    setownershipInfoDataError(errorMessege);
    setOwnershipInfoDetails([]);
  }

  async function fetchOwnershipInfo(idToken) {
    const ownershipInfoData = props.ownershipInfoData;
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    };
    await axios
      .post(
        `${REACT_APP_API_URL}/gap/ownership-info`,
        ownershipInfoData,
        config
      )
      .then((response) => {
        OwnershipInfoRes(response)
      })
      .catch(({ response }) => {
        setownershipInfoDataError("Cannot fetch ownership info details at this moment. Please try again later.");
      });
  }

  useEffect(() => {
    tokenAndRolesCapture().then((tokenResponse) => {
      fetchOwnershipInfo(tokenResponse.idToken);
    });
  }, []);

  function vshResponse(response) {
    if (response?.data?.code === 200 && response?.data?.results) {
      setManualServiceVinDetails(response?.data?.results);
      setmanualServiceVinDetailsError("");
      return;
    }
    setManualServiceVinDetails([]);
    setmanualServiceVinDetailsError("Cannot fetch service records at this moment. Please try again later");
  }

  const makeVSHCall = async () => {
    setvinDetailsLoading(true);
    const tokenResponse = await tokenAndRolesCapture();
    const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${tokenResponse.idToken}`,
        },
    };
    return axios
        .post(`${REACT_APP_API_URL}/gap/vsh`, { vin: vin, tmsGuid: props.guid, brand: brand }, config)
        .then((response) => {
          console.log("OwnershipInfoComponent :: makeVSHCall");
          setvinDetailsLoading(false);
          vshResponse(response);
        })
        .catch(({ response }) => {
          console.log("OwnershipInfoComponent :: makeVSHCall :: failed");
          setvinDetailsLoading(false);
          vshResponse(response);
        });
  };

  useEffect(async () => {
    if (vin) {
      console.log("useEffect :: VIN Changed");
      makeVSHCall();
    }
  }, [vin]);

  const loadManualServiceHistoryTable = () => (
    <ManualSeriveListingTable
      availableServiceHistoryDetails={availableServiceHistoryDetails}
      manualServiceVinDetailsError={manualServiceVinDetailsError}
      customerId={props.customerId}
      vinDetailsLoading={vinDetailsLoading}
      headerCells={headerCells}
    />
  );

  const loadDeletedServiceHistoryTable = () => (
  <DeletedServiceListingTable
    headerCells={headerCells}
    vinDetailsLoading={vinDetailsLoading}
    customerId={props.customerId} vin={vin} brand= {brand} guid={props.guid}
    deletedServiceHistoryDetails={deletedServiceHistoryDetails}
    makeVSHCall={makeVSHCall}
    manualServiceVinDetailsError={manualServiceVinDetailsError} />
  );

  const loadTable = () => tabNumber === 0 ? loadManualServiceHistoryTable() : loadDeletedServiceHistoryTable()

  return (
    <div className={classes.root} data-testid="OwnershipInfoComponent-testid">
      {ownershipInfoDataCheck(ownershipInfoDetails) ? (
        <Grid
          container
          spacing={1}
          style={{ backgroundColor: "rgb(244 246 248)", padding: "20px" }}
        >
          <Grid item xs={12}>
            <FormControl className={classes.margin} data-testid="vinSelectbox-testid">
              <NativeSelect
                id="demo-customized-select-native"
                value={vin}
                input={<BootstrapInput />}
                onChange={handleSelectChange}
              >
                {dropdownVin.map((vin, key) => (
                  <option key={vin} value={vin}>
                    {vin}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            <span style={{ paddingLeft: "50px", paddingTop: "20px" }}>
              {deleteVinLoader(deleteVinLoading)}
            </span>
          </Grid>
          <Grid item xs={12}>
            <VinListingTable
              vinTableDetails={vinTableDetails}
              guid={props.guid}
              brand={brand}
              fetchOwnershipInfo={fetchOwnershipInfo}
              setDeleteVinLoading={setDeleteVinLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <Tabs value={tabNumber}
                onChange={(event, newTabNumber) => setTabNumber(newTabNumber)}
                indicatorColor="primary" variant="fullWidth">
                <Tab
                  label="Manual Service History Details"
                  data-testid="manualServiceHistoryDetails-testid" />
                <Tab
                  label="Deleted Service History Details"
                  data-testid="deletedServiceHistoryDetails-testid" />
              </Tabs>
            </Paper>
            {
              loadTable()
            }
          </Grid>
        </Grid>
      ) : (
        checkErrorProgress(ownershipInfoDataError)
      )}
    </div>
  );
};

export default OwnershipInfoComponent;
