import React, { useState, useContext } from "react";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import Checkbox from "@material-ui/core/Checkbox";
import { CustomerContext } from "../../ContextApi/CustomersContext";
import { modalStyles } from "../../utilities/modal";
import { GenericModal } from "../GapModal/generic-modal";
import {message} from '../../utilities/message';

function ResetPasswordLink(props) {
  const [open, setOpen] = React.useState(false);
  let hideButton = false;
  const classes = modalStyles();
  const [, , tokenAndRolesCapture] = useContext(CustomerContext);
  const [checkState, setCheckState] = React.useState(false);
  const [resetEmailResponseCode, setResetEmailResponseCode] = useState(true);
  const [loading, setLoading] = useState(false);
  const [resetEmailErrorResponse, setResetEmailErrorResponse] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const emailVerified = props.emailVerified;

  const handleCheck = (event) => {
    setCheckState(!checkState);
  };

  if (emailVerified === false || checkState === false || !props.isCCDataPresent) {
    hideButton = true;
  }
  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const data = {
    email: props.email,
  };

  function resetEmailResponse(responseState) {
    setResetEmailResponseCode(responseState);
    setOpen(true);
    if(responseState){
      setTimeout(()=>setOpen(false),5000)
    }
  }

  const handleResetPassword = async () => {
    const { REACT_APP_API_URL } = process.env;
    const tokenResponse = await tokenAndRolesCapture();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${tokenResponse.idToken}`,
      },
    };
    setOpenModal(false);
    setLoading(true);
    axios.post(`${REACT_APP_API_URL}/gap/reset-password/link`, data, config)
      .then((response) => {
        setLoading(false);
        if (response.data.code === 200) {
          resetEmailResponse(true);
        } else {
          setResetEmailErrorResponse("Cannot reset your password at this moment. Please try again later.");
          resetEmailResponse(false);
        }
      })
      .catch((error) => {
        setResetEmailErrorResponse("Cannot reset your password at this moment. Please try again later.");
        setLoading(false);
        resetEmailResponse(false);
      });
  };


  return (
    <div data-testid="resetPasswordLink-testid">
      <Paper style={{ marginTop: "25px", padding: "15px" }}>
        <h3>Reset Password Link</h3>
        <Collapse in={open}>
          {resetEmailResponseCode ? (
            <Alert severity="success"
              action={
                <IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpen(false); }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >The reset password link has been successfully sent to the{" "}<b>{props.email}</b>
            </Alert>
          ) : (
            <Alert severity="error"
              action={
                <IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpen(false); }} >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            > {resetEmailErrorResponse}
            </Alert>
          )}
        </Collapse>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <FormControlLabel
              control={<Checkbox checked={checkState} onChange={handleCheck} name="checkState" color="primary" disabled={!props.isCCDataPresent || !emailVerified}
                data-testid="resetPasswordLinkCheckbox-testid" />}
              label={<b>I have verified this User Identity</b>}
            />
          </Grid>
          <Grid item>{loading ? <CircularProgress /> : null}</Grid>
          <Grid item>
            <Button color="primary" style={{ fontWeight: "bolder" }} disabled={hideButton} variant="outlined" onClick={() => { handleOpen(); }}
              data-testid="resetPasswordLinkButton-testid" className={hideButton ? clsx(classes.buttonStyle, "hideButton") : clsx(classes.buttonStyle, "buttonAccept")}
            >Send</Button>
            <GenericModal open={openModal} handleClose={handleClose} handleOk={handleResetPassword}
            title={message?.resetPassword?.title} body={message?.resetPassword?.body}></GenericModal>
          </Grid>
        </Grid>
        {emailVerified === false && props.isCCDataPresent ? (
          <Alert severity="error">{message?.alert} </Alert>
        ) : null}
      </Paper>
    </div>
  );
}

export default ResetPasswordLink;
