import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import EnhancedTableHead, {
  myStyles,
  getComparator,
  stableSort,
} from "../../utilities/SortingTable";
import Alert from "@material-ui/lab/Alert";
import * as dependency from "../../utilities/sortingTableImports";

function createData(
  serviceHistoryId,
  serviceDate,
  serviceType,
  mileage,
  customerId,
  serviceDescription
) {
  return {
    serviceHistoryId,
    serviceDate,
    serviceType,
    mileage,
    customerId,
    serviceDescription,
  };
}

export const dateConversion = (date) => {
  const newDate = Object.prototype.toString.call(date) === '[object Date]' ? date : new Date(date);
  let day = newDate?.getDate();
  let month = newDate?.getMonth() + 1;
  day = day?.toString()?.length < 2 ? `0${day}` : day;
  month = month?.toString()?.length < 2 ? `0${month}` : month;
  const fullyear = newDate?.getFullYear()?.toString();
  return `${month?.toString()}-${day?.toString()}-${fullyear}`;
};

function getServiceRecordsError(availableServiceHistoryDetails, manualServiceVinDetailsError, vinDetailsLoading) {
  if(manualServiceVinDetailsError) {
    return <Alert severity="error">{manualServiceVinDetailsError}</Alert>;
  }
  else {
    return availableServiceHistoryDetails &&
    availableServiceHistoryDetails.length === 0 &&
    !vinDetailsLoading ?
    <Alert severity="info">
      Manually entered service history records not available for this VIN.
    </Alert> : null;
  }
}

function sortOrderCheck(orderBy, property, order) {
  const isAsc = orderBy === property && order === "asc";
  return isAsc ? "desc" : "asc";
}

export default function ManualServiceListingTable(props) {
  const rows = props.availableServiceHistoryDetails.map((obj) =>
    createData(
      obj.serviceHistoryId,
      obj.serviceDate,
      obj.serviceType,
      parseInt(obj.mileage),
      parseInt(obj.customerId),
      obj.serviceDesc
    )
  );
  const classes = myStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    setPage(0);
  }, [props.availableServiceHistoryDetails]);

  const requestSort = (event, property) => {
    const sortOrder = sortOrderCheck(orderBy, property, order);
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePge = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPge = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRowsCheck = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div
      className={classes.root}
      data-testid="manualServiceListingTable-testid"
    >
      <Paper className={classes.paper}>
        <dependency.Grid container spacing={1} direction="row" justify="space-between" alignItems="baseline" style={{ padding: "20px" }}>
          <dependency.Grid item><h3>Manual Service History Details</h3><p>(For National Service History details, please check C360 Portal)</p></dependency.Grid>
          <dependency.Grid item> {props.vinDetailsLoading ? <CircularProgress data-testid="mergeServiceLoader-testid" /> : null}</dependency.Grid>
          <dependency.Grid container direction="column">
            <dependency.Grid item>
              {getServiceRecordsError(props.availableServiceHistoryDetails, props.manualServiceVinDetailsError, props.vinDetailsLoading)}
              <dependency.TableContainer>
                <dependency.Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                  <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={requestSort} rowCount={rows.length} headCells={props.headerCells} />
                  <dependency.TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <dependency.TableRow tabIndex={-1} key={row.serviceHistoryId} className={row.customerId === parseInt(props.customerId) ? "rowHighlight" : null}>
                          <dependency.TableCell component="th" id={labelId} scope="row" padding="none" align="center">{dateConversion(row.serviceDate)}
                          </dependency.TableCell>
                          <dependency.TableCell align="center">Self-Reported</dependency.TableCell>
                          <dependency.TableCell align="center"> {row.mileage}</dependency.TableCell>

                          <dependency.TableCell align="center"> {row.customerId}</dependency.TableCell>
                          <dependency.TableCell align="center">{row.serviceDescription}</dependency.TableCell>
                        </dependency.TableRow>
                      );
                    })}
                    {emptyRowsCheck > 0 && (<dependency.TableRow style={{ height: 53 * emptyRowsCheck }}><dependency.TableCell colSpan={6} /></dependency.TableRow>)}
                  </dependency.TableBody>
                </dependency.Table>
              </dependency.TableContainer>
              <dependency.TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onChangePage={handleChangePge}
                onChangeRowsPerPage={handleChangeRowsPerPge}
              />
            </dependency.Grid>
          </dependency.Grid>
        </dependency.Grid>
      </Paper>
    </div>
  );
}
