export const NAME_REGEX = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
export const POSTAL_CODE_REGEX = /^[0-9]{5}$/;
export const PHONE_CODE_REGEX = /^(?!0)[0-9-]{4}[1-9-][0-9-]{7}$/;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VIN_REGEX = /^([a-zA-Z0-9]{17})$/;
export const BUSINESS_NAME_REGEX = /^([A-Za-z0-9!@#$%^&*()_,.<>'";:-]+\s)*[A-Za-z0-9!@#$%^&*()_,.<>'";:-]+$/;
export const CHANGE_EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
export const PASSWORD_REGEX = new RegExp('^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_])' +
    '|(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_])|(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*()_]))[A-Za-z0-9!@#$%^&*()]{8,16}$'
)
