import React, { useState, useEffect, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import { CustomerContext } from "../../ContextApi/CustomersContext";
import axios from "axios";
import EnhancedTableHead, {
  myStyles,
  getComparator,
  stableSort,
} from "../../utilities/SortingTable";
import {message} from '../../utilities/message';

import * as dependency from "../../utilities/sortingTableImports";
import { GenericModal } from "../GapModal/generic-modal";

function sortOrderCheck(orderBy, property, order) {
  const isAsc = orderBy === property && order === "asc";
  return isAsc ? "desc" : "asc";
}

function dateFormat(date) {
  return date !== "-" ? Moment(date).format("MM/DD/YYYY") : "-";
}

function checkSubscriptionDetails(row, roleType) {
  return (row.status === "-" && !(row.role === "ENFORM REMOTE USER" || row.role === "PRIMARY SUBSCRIBER" || row.role === "SECONDARY SUBSCRIBER") && roleType);
}

function emptyTableRows(emptyRows) {
  return emptyRows > 0 && (
    <dependency.TableRow style={{ height: 53 * emptyRows }}>
      <dependency.TableCell colSpan={12} />
    </dependency.TableRow>
  )
}

function createData({ vin, brand, model, year, role, subscription, effectiveDT, expirationDT, status }, action) {
  return {
    vin,
    brand,
    model,
    year,
    role,
    subscription,
    effectiveDT,
    expirationDT,
    status,
    action,
  };
}

const headCells = [
  {
    id: "vin",
    numeric: false,
    disablePadding: false,
    label: "VIN",
  },
  { id: "brand", numeric: false, disablePadding: false, label: "Brand" },
  { id: "model", numeric: false, disablePadding: false, label: "Model" },
  { id: "year", numeric: true, disablePadding: false, label: "Year" },
  { id: "role", numeric: false, disablePadding: false, label: "Role" },
  {
    id: "subscription",
    numeric: false,
    disablePadding: false,
    label: "Subscription",
  },
  {
    id: "effectiveDT",
    numeric: false,
    disablePadding: false,
    label: "Start Date",
  },
  { id: "expirationDT", numeric: false, disablePadding: false, label: "End Date" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "action", numeric: false, disablePadding: false, label: "Action" },
];

export default function VinListingTable(props) {
  const { REACT_APP_API_URL } = process.env;
  const rows = [];
  const [vinTableDetails, setVinTableDetails] = useState(props.vinTableDetails);
  const [, , tokenAndRolesCapture] = useContext(CustomerContext);
  const [roleType, setRoleType] = useState(false);

  useEffect(() => {
    setVinTableDetails(props.vinTableDetails);
  }, [props.vinTableDetails]);

  useEffect(() => {
    tokenAndRolesCapture().then((response) => {
      if (response.idTokenClaims.roles !== undefined) {
        const roleType = response.idTokenClaims.roles.some((role) =>
          role.includes("GAP.SuperUser")
        );
        setRoleType(roleType);
      }
    });
  });
  (function () {
    vinTableDetails.forEach(vinTableDetails => {
      if (vinTableDetails.subscription && vinTableDetails.subscription.length > 0) {
        vinTableDetails.subscription.forEach((subscriptionDetail) =>
          rows.push(createData(
            {
              vin: vinTableDetails.vin,
              brand: vinTableDetails.brand,
              model: vinTableDetails.model,
              year: vinTableDetails.year,
              role: vinTableDetails.role,
              subscription: subscriptionDetail.productName,
              effectiveDT: subscriptionDetail.effectiveDT,
              expirationDT: subscriptionDetail.expirationDT,
              status: subscriptionDetail.status
            }
          ))
        );
      } else {
        rows.push(
          createData(
            {
              vin: vinTableDetails.vin,
              brand: vinTableDetails.brand,
              model: vinTableDetails.model,
              year: vinTableDetails.year,
              role: vinTableDetails.role,
              subscription: "-",
              effectiveDT: "-",
              expirationDT: "-",
              status: "-"
            }
          ),
        );
      }
    });
  })();

  const classes = myStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("vin");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const sortOrder = sortOrderCheck(orderBy, property, order);
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const deleteVinApiCall = async (idToken) => {
    setOpenModal(false);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    };
    props.setDeleteVinLoading(true);
    await axios.post(`${REACT_APP_API_URL}/gap/delete/vin`, { tmsGuid: props.guid, vin: vinTableDetails[0].vin, brand: props.brand}, config)
      .then(() => {
        props.fetchOwnershipInfo(idToken);
        props.setDeleteVinLoading(false);
      })
      .catch(({ error }) => {
        props.setDeleteVinLoading(false);
      });
  };

  async function handleDeleteVin() {
    tokenAndRolesCapture().then((tokenResponse) => {
      deleteVinApiCall(tokenResponse.idToken);
    });
  }
   const bodydyMsg=
   <div style={{ textAlign: "center" }}>
    Do you want to delete the VIN <b>{props?.vinTableDetails[0] &&  props.vinTableDetails[0].vin}</b> from the user account?
   </div>

  return (
    <div className={classes.root} data-testid="vinListingTable-testid">
      <Paper className={classes.paper}>
        <dependency.Grid container direction="row" justify="center" alignItems="center">
          <dependency.Grid item xs={12}>
            <dependency.TableContainer>
              <dependency.Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} headCells={headCells} />
                <dependency.TableBody data-testid='tableBody-testid'>
                  {stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <dependency.TableRow tabIndex={-1} key={index} data-testid='tableRow-testid'>
                        <dependency.TableCell data-testid='tableCell-testid' component="th" id={labelId} scope="row" align="center">{row.vin}</dependency.TableCell>
                        <dependency.TableCell align="center">{row.brand}</dependency.TableCell>
                        <dependency.TableCell align="center">{row.model}</dependency.TableCell>
                        <dependency.TableCell align="center">{row.year}</dependency.TableCell>
                        <dependency.TableCell align="center">{row.role}</dependency.TableCell>
                        <dependency.TableCell align="center">{row.subscription}</dependency.TableCell>
                        <dependency.TableCell align="center">{dateFormat(row.effectiveDT)}</dependency.TableCell>
                        <dependency.TableCell align="center">{dateFormat(row.expirationDT)}</dependency.TableCell>
                        <dependency.TableCell align="center"> {row.status} </dependency.TableCell>
                        <dependency.TableCell align="center">
                          { checkSubscriptionDetails(row, roleType) ? (
                            <React.Fragment>
                              <Tooltip title="Delete">
                                <IconButton onClick={() => { handleOpen(); }} data-testid="deleteVinButton-testid">
                                  <DeleteForeverIcon title="Delete" />
                                </IconButton>
                              </Tooltip>
                                <GenericModal open={openModal} handleClose={handleClose} handleOk={handleDeleteVin} title={message?.deleteVehicle?.title} body={bodydyMsg} />
                            </React.Fragment>
                          ) : ("-")}
                        </dependency.TableCell>
                      </dependency.TableRow>

                    );
                  })}
                  {emptyTableRows(emptyRows)}
                </dependency.TableBody>
              </dependency.Table>
            </dependency.TableContainer>
            <dependency.TablePagination
              rowsPerPageOptions={[5, 10, 25]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              data-testid='pagination-testid'
            />
          </dependency.Grid>
        </dependency.Grid>
      </Paper>
    </div>
  );
}
