import React, { useState, useContext } from "react";
import { CustomerContext } from "../../ContextApi/CustomersContext";
import timestampToDate from "timestamp-to-date";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import CustomerLandingComponent from "./CustomerLandingComponent";
import EnhancedTableHead, {
  myStyles,
  getComparator,
  stableSort,
} from "../../utilities/SortingTable";
import * as dependency from "../../utilities/sortingTableImports";
import CustomerDataComponent from "./CustomerDataComponent";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import { List, ListItem, ListItemText } from "@material-ui/core";

function getSearchByEmail(userInputData) {
  return userInputData !== undefined ? (
    <h3 className="SearchHighlight"> {`Search results for Search By "Email : ${userInputData.email}"`}</h3>
  ) : null;
}

function getSearchTypeInput(userInputData) {
  return userInputData !== undefined && userInputData.searchBy === "name" ? (
    <h3 className="SearchHighlight">{`Search results for Search By "Name : ${userInputData.firstName} ${userInputData.lastName}"`}</h3>
  ) : (
    getSearchByEmail(userInputData)
  );
}

function getSearchByVin(userInputData) {
  return userInputData !== undefined ? (
    <h3 className="SearchHighlight">{`Search results for Search By "VIN : ${userInputData.vin}"`}</h3>
  ) : null;
}

function getSearchByBusiness(userInputData) {
  return userInputData !== undefined ? (
    <h3 className="SearchHighlight">{`Search results for Search By "Business Name: ${userInputData.businessName}" `}</h3>
  ) : null;
}

function getSearchByPhone(userInputData) {
  return userInputData !== undefined ? (
    <h3 className="SearchHighlight">{`Search results for Search By "Phone : ${userInputData.phone}"`}</h3>
  ) : null;
}

function checkCustomersData(customers) {
  return customers !== undefined ? (
    <h3 style={{ textAlign: "center" }}>
      {customers.description || customers.message || customers.error}
    </h3>
  ) : (
    <CustomerLandingComponent />
  );
}

function nullCheckFunction(value) {
  return value ? value : "";
}

function sortOrderCheck(orderBy, property, order) {
  const isAsc = orderBy === property && order === "asc";
  return isAsc ? "desc" : "asc";
}

function checkSearchBy(searchBy, userInputData) {
  switch (searchBy) {
    case "common":
      return getSearchTypeInput(userInputData);
    case "vin":
      return getSearchByVin(userInputData);
    case "business":
      return getSearchByBusiness(userInputData);
    case "phone":
      return getSearchByPhone(userInputData);
    default:
      return null;
  }
}

function loadingFunction(loading) {
  return loading ? <div> <LinearProgress /></div> : null;
}

function dateFormat(date) {
  return date === "" ? "-" : timestampToDate(date, "MM/dd/yyyy")
}

function emptyTableRows(emptyRows) {
  return emptyRows > 0 && (
    <dependency.TableRow style={{ height: 53 * emptyRows }}>
      <dependency.TableCell colSpan={12} />
    </dependency.TableRow>
  )
}

export default function CustomerListingComponent(props) {
  const { REACT_APP_API_URL } = process.env;
  const [loading, setLoading] = useState(false);
  const classes = myStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [customers, userInputData, tokenAndRolesCapture, [, setcustomerDetails],] = useContext(CustomerContext);
  const history = useHistory();
  let rows = [];

  async function userDetailsApiCall(email, idToken) {
    setLoading(true);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    };
    const data = {
      email: email,
    };

    await axios.post(`${REACT_APP_API_URL}/gap/user-account-info`, data, config)
      .then((response) => {
        setLoading(false);
        setcustomerDetails({ customerDetail: response.data.results });
        history.push({
          pathname: "/customer-details",
          emailId: email,
        });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  async function handleUserDetails(email) {
    tokenAndRolesCapture().then((tokenResponse) => {
      userDetailsApiCall(email, tokenResponse.idToken);
    });
  }

  function getMappedRowData(customers) {
    if (customers !== undefined && customers.results !== null && customers.message !== null && customers.message !== "") {
      switch (props.searchType) {
        case "common":
          rows = customers.results.map((obj, key) =>
            props.createData(
              {
                serialNo: key + 1,
                email: nullCheckFunction(obj.email),
                firstName: nullCheckFunction(obj.firstName),
                status: nullCheckFunction(obj.status),
                lastName: nullCheckFunction(obj.lastName),
                ownershipType: nullCheckFunction(obj.ownershipType),
                accountCreatedDate: nullCheckFunction(obj.accountCreatedDate)
              }
            )
          );
          break;
        case "vin":
          rows = customers.results.map((obj, key) =>
            props.createData(
              {
                serialNo: key + 1,
                vin: nullCheckFunction(obj.vin),
                email: nullCheckFunction(obj.email),
                firstName: nullCheckFunction(obj.firstName),
                lastName: nullCheckFunction(obj.lastName),
                status: nullCheckFunction(obj.status),
                ownershipType: nullCheckFunction(obj.ownershipType),
                accountCreatedDate: nullCheckFunction(obj.accountCreatedDate)
              }

            )
          );
          break;
        case "business":
          rows = customers.results.map((obj, key) =>
            props.createData(
              {
                serialNo: key + 1,
                email: nullCheckFunction(obj.email),
                status: nullCheckFunction(obj.status),
                businessName: nullCheckFunction(obj.businessName),
                ownershipType: nullCheckFunction(obj.ownershipType),
                accountCreatedDate: nullCheckFunction(obj.accountCreatedDate)
              }
            )
          );
          break;
        case "phone":
          rows = customers.results.map((obj, key) =>
            props.createData(
              {
                serialNo: key + 1,
                phone: nullCheckFunction(obj.phone),
                email: nullCheckFunction(obj.email),
                firstName: nullCheckFunction(obj.firstName),
                lastName: nullCheckFunction(obj.lastName),
                status: nullCheckFunction(obj.status),
                ownershipType: nullCheckFunction(obj.ownershipType),
                accountCreatedDate: nullCheckFunction(obj.accountCreatedDate)
              }
            )
          );
          break;
        default:
          break;
      }
    }
  }

  getMappedRowData(customers);

  const requestSort = (event, property) => {
    const sortOrder = sortOrderCheck(orderBy, property, order);
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const changePage = (event, newPage) => {
    setPage(newPage);
  };

  const changeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const loadMore = () => setOpen(prevOpenState => !prevOpenState);

  return (
    <div className={classes.root} data-testid="customerListingComponent-testid">
      {loadingFunction(loading)}
      <dependency.Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <dependency.Grid item xs={12}>
          {customers !== undefined &&
            customers.results !== null &&
            !customers.hasOwnProperty("message") ? (
            <div>
              {checkSearchBy(props.searchType, userInputData)}
              <dependency.TableContainer>
                <dependency.Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                  <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={requestSort} rowCount={rows.length} headCells={props.headCells}
                    customerTableHead={true} />
                  <dependency.TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <dependency.TableRow tabIndex={-1} key={index}>
                            <dependency.TableCell component="th" id={labelId} scope="row" padding="none" className="customer-search-table" align="center">{index + 1}
                            </dependency.TableCell>
                            <CustomerDataComponent row={row} keys={props.keys} />
                            <dependency.TableCell align="center" className="customer-search-table">
                              {dateFormat(row.accountCreatedDate)}
                            </dependency.TableCell>
                            <dependency.TableCell align="center" className="customer-search-table">
                              <dependency.Button variant="outlined" color="primary"
                                className={clsx(classes.buttonStyle, "buttonAccept")} data-testid='showDetailsButton-testid'
                                style={{ fontWeight: "bolder" }} onClick={() => handleUserDetails(row.email)}>Show Details
                              </dependency.Button>
                            </dependency.TableCell>
                          </dependency.TableRow>
                        );
                      })}
                  </dependency.TableBody>
                </dependency.Table>
              </dependency.TableContainer>
              <dependency.TablePagination rowsPerPageOptions={[10, 20, 30]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onChangePage={changePage}
                onChangeRowsPerPage={changeRowsPerPage}
              />
            </div>
          ) : (
            checkCustomersData(customers)
          )}
        </dependency.Grid>
      </dependency.Grid>
      <div id="load-more-menu" style={{ padding: 10, display: customers?.moreResults ? "block": "none" }}>
        <div>
          <Collapse in={open}>
            <Alert severity="warning">All users weren't loaded in the above table. Please search using the below email addresses for the remaining users.</Alert>
            <List>{customers?.moreResults?.map((email, key) => <ListItem disableGutters key={key}><ListItemText primary={`• ${email}`}/></ListItem>)}
            </List>
          </Collapse>
        </div>
        <dependency.Button variant="outlined" color="primary"
        className={clsx(classes.buttonStyle, "buttonAccept")}
        data-testid='loadmore-testid' style={{ fontWeight: "bolder", marginTop: 10 }}
        onClick={loadMore}>{open ? "Show Less" : "Show More"}</dependency.Button>
      </div>
    </div>
  );
}
