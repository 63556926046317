import React from 'react';
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import FolderSharedIcon from "@material-ui/icons/FolderShared";
import Collapse from "@material-ui/core/Collapse";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import GroupIcon from "@material-ui/icons/Group";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 240,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: 240,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    nested: {
        paddingLeft: "37px",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    quicklinkText: {
        paddingLeft: "15px",
    },
}));

function iconBasedOnDirection(direction) {
    if (direction === "rtl") {
        return <ChevronRightIcon />
    }
    else {
        return <ChevronLeftIcon />
    }
}

function iconBasedOnSublink(sublink) {
    return sublink ? <ExpandLess /> : <ExpandMore />
}


function windowResize() {
    setTimeout(() => {
        const resizeEvent = window.document.createEvent("UIEvents");
        resizeEvent.initUIEvent("resize", true, false, window, 0);
        window.dispatchEvent(resizeEvent);
    }, 60);
}

function SideDrawer({ toggle, setToggle, setGifPath, open, setOpen }) {
    const sideDrawerClasses = useStyles();
    const theme = useTheme();
    const [openSublink, setOpenSublink] = React.useState(false);
    const [openCookBooklink, setOpenCookBookSublink] = React.useState(false);
    const url=window.location.origin;
    const handleToggle = (path) => {
        setToggle(!toggle);
        setGifPath(path);
    };
    const handleDrawerClose = () => {
        setOpen(false);
        windowResize();
    };
    const handleSublink = () => {
        setOpenSublink(!openSublink);
    };
    const handleCookBooklink = () => {
        setOpenCookBookSublink(!openCookBooklink);
    };
    const quickLinks = [
        {
            path: "https://www.toyota.com/owners/register",
            text: "Create TO Account"
        },
        {
            path: "https://drivers.lexus.com/lexusdrivers/account/register",
            text: "Create LD Account"
        },
        {
            path: "https://ctportal.telematicsct.com/dashboard/admin",
            text: "Launch CTP"
        },
    ]

    return (
        <Drawer variant="permanent"
            className={clsx(sideDrawerClasses.drawer, { [sideDrawerClasses.drawerOpen]: open, [sideDrawerClasses.drawerClose]: !open, })}
            classes={{ paper: clsx({ [sideDrawerClasses.drawerOpen]: open, [sideDrawerClasses.drawerClose]: !open, }), }} data-testid='sidebarDrawer-testid'
        >
            <div className={sideDrawerClasses.toolbar} id="drawerclose">
                <img src="/toyota-lexus-6-01.png" alt="toyota" />
                <IconButton onClick={handleDrawerClose} data-testid='drawerClosebutton-testid'> {iconBasedOnDirection(theme.direction)} </IconButton>
            </div>
            <List>
                <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                    <ListItem button key="0" data-testid="dashboard-testid">
                        <ListItemIcon> <AvTimerIcon /> </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                </Link>
                <ListItem button key="1" onClick={handleSublink} data-testid="quickLinks-testid" id="handlesublink" >
                    <ListItemIcon><FolderSharedIcon /></ListItemIcon>
                    <ListItemText primary="Quick Links" />
                    {iconBasedOnSublink(openSublink)}
                </ListItem>
                <Collapse in={openSublink} timeout="auto" unmountOnExit>
                    {quickLinks.map((item, index) => (
                        <Link key={index} to={{ pathname: item.path }}
                            target="_blank" style={{ textDecoration: "none", color: "inherit" }}>
                            <List component="div" disablePadding>
                                <ListItem button className={sideDrawerClasses.nested} data-testid="registerUser-testid">
                                    <ListItemIcon>
                                        <FolderSharedIcon style={{ marginTop: "4px", width: "0.8em" }} />
                                        <ListItemText disableTypography className={sideDrawerClasses.quicklinkText}
                                            primary={<Typography type="body1" style={{ fontSize: "14px" }}>{item.text}</Typography>} />
                                        <OpenInNewIcon style={{ paddingLeft: "10px" }} />
                                    </ListItemIcon>
                                </ListItem>
                            </List>
                        </Link>
                    ))}
                </Collapse>
            </List>
            <List>
                <ListItem button key="2" onClick={handleCookBooklink} data-testid="cookbook-testid" id="handleCookBooklinkid">
                    <ListItemIcon> <SettingsApplicationsIcon /></ListItemIcon>
                    <ListItemText primary="CookBook" />
                    {iconBasedOnSublink(openCookBooklink)}
                </ListItem>
                <Collapse in={openCookBooklink} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button className={sideDrawerClasses.nested} data-testid="registerUser-testid" onClick={() => handleToggle(`${url}/cookbook/Email.mp4`)}>
                            <ListItemIcon>
                                <MailOutlineIcon style={{ marginTop: "4px", width: "0.8em" }} />
                                <ListItemText style={{ paddingLeft: "15px" }} primary="Search by Email" />
                            </ListItemIcon>
                        </ListItem>
                    </List>
                    <List component="div" disablePadding>
                        <ListItem button className={sideDrawerClasses.nested} data-testid="cookbookVin-testid" onClick={() => handleToggle(`${url}/cookbook/VIN.mp4`)}>
                            <ListItemIcon>
                                <span className="iconify" data-icon="mdi-barcode" data-inline="true" data-width="28.5" ></span>
                                <ListItemText style={{ paddingLeft: "15px" }} primary="Search by VIN" />
                            </ListItemIcon>
                        </ListItem>
                    </List>
                    <List component="div" disablePadding>
                        <ListItem button className={sideDrawerClasses.nested} data-testid="cookbookName-testid" onClick={() => handleToggle(`${url}/cookbook/Name.mp4`)}>
                            <ListItemIcon>
                                <PersonOutlineIcon style={{ marginTop: "4px", width: "0.8em" }} />
                                <ListItemText style={{ paddingLeft: "15px" }} primary="Search by Name" />
                            </ListItemIcon>
                        </ListItem>
                    </List>
                    <List component="div" disablePadding>
                        <ListItem button className={sideDrawerClasses.nested} data-testid="cookbookBusiness-testid" onClick={() => handleToggle(`${url}/cookbook/Business.mp4`)}>
                            <ListItemIcon>
                                <GroupIcon style={{ marginTop: "4px", width: "0.8em" }} />
                                <ListItemText style={{ paddingLeft: "15px" }} primary="Search by Business" />
                            </ListItemIcon>
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </Drawer>
    )
}
export default SideDrawer;
