import React from "react";
import Modal from "@material-ui/core/Modal";
import { useMsal } from "@azure/msal-react";
import { Button } from "@material-ui/core";
import { getModalStyle, modalStyles } from "../../utilities/modal";
export default function UnAuthenticatedComponent() {
  const { instance } = useMsal();
  const classes = modalStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.modalPaper}>
      <h2 id="simple-modal-title">Permisson Issue</h2>
      <p id="simple-modal-description">
        You are not entitled to use this application
      </p>
      <Button
        onClick={() => {
          instance.logout();
        }}
        size="medium"
        variant="outlined"
        className="buttonAccept"
        data-testid="acceptButton-testid"
      >
        <b>Logout</b>
      </Button>
    </div>
  );

  return (
    <div data-testid="unauthenticated-testid">
      <Modal
        open={true}
        onClose={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
