import React from "react";
import CustomerListingComponent from "./CustomerListingComponent";
import * as heads from '../../utilities/customerTableHeads';

const keys = [
  "phone",
  "email",
  "firstName",
  "lastName",
  "status",
  "ownershipType",
];

const phoneHeadCells = [
  heads.PHONE_HEAD_CELL,
  heads.EMAIL_HEAD_CELL,
  heads.FIRST_NAME_HEAD_CELL,
  heads.LAST_NAME_HEAD_CELL,
  heads.STATUS_HEAD_CELL,
  heads.OWNERSHIPTYPE_HEAD_CELL,
  heads.ACCOUNTCREATEDDATE_HEAD_CELL
];

export default function CustomerPhoneListingComponent() {
  function createData({ serialNo, phone, email, status, firstName, lastName, ownershipType, accountCreatedDate }, action) {
    return {
      serialNo,
      phone,
      email,
      firstName,
      lastName,
      status,
      ownershipType,
      accountCreatedDate,
      action,
    };
  }

  return (
    <div data-testid="customerPhoneListingComponent-testid">
      <CustomerListingComponent
        keys={keys}
        headCells={phoneHeadCells}
        createData={createData}
        searchType="phone"
      />
    </div>
  );
}
