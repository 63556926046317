import React from 'react';
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import GroupIcon from "@material-ui/icons/Group";
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
    },
}))

function SearchingTabs({ searchValue, setSearchValue }) {
    const searchingTabsClasses = useStyles();
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            item style={{ backgroundColor: "#FFFFFF" }}
        >
            <Tabs
                style={{ backgroundColor: "#FFFFFF" }}
                value={searchValue}
                onChange={(event, newValue) => { setSearchValue(newValue); }}
                className={searchingTabsClasses.root}
                data-testid='searchingTabs-testid'
                id="tabs">
                <Tab
                    label="EMAIL"
                    icon={
                        <MailOutlineIcon
                            style={{ fontSize: "2.5rem" }}
                            size="large" />}
                    id="email"
                    data-testid="emailSearch-testid" />
                <Tab
                    label="VIN"
                    icon={
                        <span className="iconify"
                            data-icon="mdi-barcode"
                            data-inline="false" data-width="45"
                        ></span>}
                    data-testid="vinSearch-testid" />
                <Tab
                    label="NAME"
                    icon={
                        <PersonOutlineIcon
                            style={{ fontSize: "2.5rem" }}
                            size="large"
                        />}
                    data-testid="nameSearch-testid" />
                <Tab
                    label="BUSINESS"
                    icon={
                        <GroupIcon
                            style={{ fontSize: "2.5rem" }}
                            size="large"
                        />}
                    data-testid="businessSearch-testid" />
                <Tab
                    label="PHONE"
                    icon={
                        <ContactPhoneIcon
                            style={{ fontSize: "2.5rem" }}
                            size="large"
                        />}
                 />
            </Tabs>
        </Grid>
    )
}

export default SearchingTabs;
