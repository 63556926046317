import React, { useState, useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import Checkbox from "@material-ui/core/Checkbox";
import { CustomerContext } from "../../ContextApi/CustomersContext";
import { PHONE_CODE_REGEX } from '../../utilities/regexPatterns';
import TextField from "@material-ui/core/TextField";

const myStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  buttonStyle: {
    [theme.breakpoints.down("md")]: {
      padding: "1px!important",
      fontSize: "9px!important",
    },
  },
}));

function buttonStyle(disableButton, classes) {
  return disableButton ? clsx(classes.buttonStyle, "disableButton") : clsx(classes.buttonStyle, "buttonAccept")
}

const formatRequestData = (inputData, guid) => {
  let requestData = {};
  const mobileAreaCode = inputData?.substring(0, 3)
  const phoneNumberData = inputData?.replace(/-/g, '').substring(3, 10)
   requestData = {
      guid: guid,
      areaCode: mobileAreaCode,
      phoneNo: phoneNumberData,
  }
  return requestData;
}

function ChangePhoneNumber(props) {
  const { REACT_APP_API_URL } = process.env;
  const { setPhoneNumberChanged } = props;
  const classes = myStyles();
  const [, , tokenAndRolesCapture, [customerDetails, setcustomerDetails]] = useContext(CustomerContext);
  const [open, setOpen] = useState(false);
  const [loadingForChangeEmail, setLoadingForChangeEmail] = useState(false);
  const [checkedState, setCheckedState] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [phoneNumberAlertCheck, setPhoneNumberAlertCheck] = useState(false);
  const [phoneNumberErrorCheck,setphoneNumberErrorCheck] = useState("");
  const guid = props.guid
  let disableButton = false;
  const [fieldsData, setFieldsData] = useState(null);

  if (checkedState === false || phoneNumber === "" || phoneNumberError !== "" || !props.isCCDataPresent) {
    disableButton = true;
  }
  const handleChange = (event) => {
    setCheckedState(!checkedState);
  };

  const formatPhoneNumber = (value) => {
    let errorMessage = "";
    const phoneRegex = PHONE_CODE_REGEX;
    const result = value
      .split("")
      .filter((x) => !isNaN(Number.parseInt(x)))
      .map((x) => Number.parseInt(x));
    result.length > 3 && result.splice(3, 0, "-");
    result.length > 7 && result.splice(7, 0, "-");
    const inputValue = result.join("");
    setFieldsData(inputValue);
    errorMessage = phoneRegex.test(inputValue) || inputValue.length === 0 ? "" : "Phonenumber is not valid";
    setPhoneNumber(inputValue);
    setPhoneNumberError(errorMessage);
  };

  const handlePhoneNumberUpdate = async () => {
    const updatedRequest = formatRequestData(fieldsData, guid );
    setLoadingForChangeEmail(true);
    const tokenResponse = await tokenAndRolesCapture();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${tokenResponse.idToken}`,
      },
    };
    axios.post(`${REACT_APP_API_URL}/gap/change/phoneNumber`, updatedRequest, config)
      .then((response) => {
        setLoadingForChangeEmail(false);
        if(response.data.code === 200) {
          setcustomerDetails({
            customerDetail: {
              ...customerDetails.customerDetail,
              primaryNumber: {
                ...customerDetails.customerDetail.primaryNumber,
                areaCode: updatedRequest.areaCode,
                phoneNo: updatedRequest.phoneNo
              }
            }
          });
          setPhoneNumberChanged(updatedRequest)
          setPhoneNumberAlertCheck(true);
          setOpen(true);
          setTimeout(()=>setOpen(false),5000)
          setPhoneNumber("");
        } else if(response.data.code === 301){
          setPhoneNumberAlertCheck(false);
         setOpen(true);
         setphoneNumberErrorCheck("Phone Number already in use – The entered phone number is already in use, please try again with a different number.");
         setPhoneNumber("");
        }
        else{
          setPhoneNumberAlertCheck(false);
          setOpen(true);
          setphoneNumberErrorCheck("Cannot change phone number at this moment. Please try again later.");
          setPhoneNumber("");
        }
      })
      .catch((error) => {
        setLoadingForChangeEmail(false);
        setPhoneNumberAlertCheck(false);
        setOpen(true);
        setphoneNumberErrorCheck("Cannot change phone number at this moment. Please try again later.");
        setPhoneNumber("");
      });
  };

  const isDisabled = () => !props.isCCDataPresent;

  return (
    <div >
      <Paper style={{ marginTop: "0px", padding: "15px" }}>
        <h3>Change Primary Contact</h3>
        <Collapse in={open}>{phoneNumberAlertCheck ? (
          <Alert severity="success"
            action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpen(false); }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>}
          > Phone number update successful.
          </Alert>
        ) : (
          <Alert severity="error"
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpen(false); }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          > {phoneNumberErrorCheck}
          </Alert>
        )}
        </Collapse>
        <br/>
        <Grid container direction="row" justify="space-between" alignItems="center" style={{ marginTop: "-10px", marginBottom: "10px" }}>
          <Grid item>
              <TextField id="outlined-basic" variant="outlined" name="changeEmail"
              inputProps={{ maxLength: 12, "data-testid": "changePhoneNumberInputField-testid" }}
              value={phoneNumber} helperText={phoneNumberError}
              onChange={(e) => formatPhoneNumber(e.target.value)} label="Enter Phone number" required disabled={isDisabled()} />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={<Checkbox checked={checkedState} onChange={handleChange} name="checkedState" color="primary" disabled={isDisabled()} />}
              label={<b>I have verified this User Identity</b>}
              data-testid="changePhoneNumberCheckbox-testid"
            />
          </Grid>
          {loadingForChangeEmail ? <CircularProgress /> : null}
          <Grid item>
            <Button color="primary" data-testid="changePhoneNumberButton-testid"
              className={buttonStyle(disableButton, classes)}
              style={{ fontWeight: "bolder" }} disabled={disableButton} variant="outlined" onClick={handlePhoneNumberUpdate}
            > Change</Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default ChangePhoneNumber;
