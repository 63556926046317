import React, { Component } from "react";
import DisclaimerPageAcceptButton from "./Components/DisclaimerPage/DisclaimerPageAcceptButton";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./Components/authConfig";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Sidebar from "./Components/Navbar/Sidebar";

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#EB0A1E",
    },
  },

  typography: {
    fontFamily: "Toyota-Light",
  },
});

class App extends Component {
  state = {};

  render() {
    const msalInstance = new PublicClientApplication(msalConfig);
    return (
      <div>
        <MsalProvider instance={msalInstance}>
          <ThemeProvider theme={outerTheme}>
            <AuthenticatedTemplate>
              <Sidebar />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <DisclaimerPageAcceptButton />
            </UnauthenticatedTemplate>
          </ThemeProvider>
        </MsalProvider>
      </div>
    );
  }
}

export default App;
