import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import DisclaimerPageBody from "./DisclaimerPageBody";
import { Button } from "@material-ui/core";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

const useStyles = makeStyles((theme) => ({
  paddingAcceptButton: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "80px",
      paddingRight: "0px",
      paddingTop: "50px",
      paddingBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "50px",
      paddingBottom: "50px",
    },
  },

  paddingAcceptButtonNormal: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },

  marginBody: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "75px",
      marginRight: "75px",
      marginTop: "100px",
      marginBottom: "100px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "48px",
      marginRight: "48px",
      marginTop: "15px",
      marginBottom: "15px",
    },
  },

  marginBodyNormal: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "0px",
      marginRight: "0px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
      marginRight: "0px",
      marginTop: "0px",
      marginBottom: "0px",
    },
  },
}));

function DisclaimerPageAcceptButton() {
  const [count, setCount] = useState(true);
  const { instance } = useMsal();

  const classes = useStyles();

  return (
    <div
      style={{ backgroundColor: "white" }}
      className={count ? classes.marginBody : classes.marginBodyNormal}
      data-testid="disclaimerPageAcceptButton-testid"
    >
      {count ? <DisclaimerPageBody /> : null}

      <div
        style={{ backgroundColor: "white" }}
        className={count ? "acceptClassActive" : "acceptClassInactive"}
      >
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
        >
          <Grid item></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={2}
            className={
              count
                ? classes.paddingAcceptButton
                : classes.paddingAcceptButtonNormal
            }
          >
            <Button
              onClick={async () => {
                setCount(false);
                await instance.handleRedirectPromise();
                const acounts = instance.getAllAccounts();
                if(acounts.length === 0){
                 await instance.loginRedirect(loginRequest);
                }
              }}
              size="medium"
              variant="outlined"
              className="buttonAccept"
              data-testid="acceptButton-testid"
            >
              <b>I Agree</b>
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default DisclaimerPageAcceptButton;
