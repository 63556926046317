
export const message = {
   okay: 'Okay',
   cancel:'Cancel',
   restore: 'Restore',
   changeEmail: {
    title:'Change Login Email ID',
    body:'Are you sure, you want to change the Login Email Id?'
   },
   resetPassword:{
    title:'Reset Password Link',
    body:'This action will send an email to the user account to reset the password. Password will be valid for all Toyota Owners and Lexus Drivers Applications'
   },
   deleteVehicle:{
    title:'Confirm Delete Vehicle',
   },
   restoreDelete:{
      title:'Restore Deleted Service Records',
      body:`Prior to merging accounts and recovering deleted service history,
            please verify ownership information.
            Deleted records cannot be recovered if vehicle has changed to a different owner.`,
      additionalNote1: '*Deleted records available from October 2022'
   },
   alert:`Please Activate user's account before sending reset password link.`,
   deleteAlert: 'Deleted manually entered service history records not available for this VIN.'
}
