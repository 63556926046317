import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AppRouter from "../Router/AppRouter";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { CustomerProvider } from "../../ContextApi/CustomersContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import { loginRequest } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import UnAuthenticatedComponent from "../UnAuthenticatedComponent/UnAuthenticatedComponent";
import Tooltip from "@material-ui/core/Tooltip";
import { matchPath } from "react-router";
import SideDrawer from './SideDrawer';
import SearchInputField from "./SearchInputField";
import Cookbook from '../Cookbook/Cookbook';
import SearchingTabs from './SearchingTabs';

const useStyles = makeStyles((theme) => ({
  toyoImg: {
    [theme.breakpoints.only("sm")]: {
      padding: "25px",
      backgroundColor: "white",
      margin: "-31px",
      paddingBottom: "10px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "25px",
      backgroundColor: "white",
      margin: "-31px",
      paddingBottom: "10px",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "25px",
      backgroundColor: "white",
      margin: "-31px",
      paddingBottom: "10px",
    },
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - 240px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
}));

function windowResize() {
  setTimeout(() => {
    const resizeEvent = window.document.createEvent("UIEvents");
    resizeEvent.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(resizeEvent);
  }, 60);
}

function urlMatch() {
  const match = matchPath(window.location.href, {
    path: [process.env.REACT_APP_REDIRECT_URL, `${process.env.REACT_APP_REDIRECT_URL}customer-details`,],
    exact: true,
    strict: true,
  });
  return match === null ? true : false;
}

function customerRoutes(value) {
  if (value === 0 || value === 2) {
    return "/customer-common-list";
  } else if (value === 1) {
    return "/customer-vin-list";
  } else if(value === 3){
    return "/customer-business-list";
  }else if(value === 4){
    return "/customer-phone-list";
  }
  return "/customer-error";
}

function searchData(searchType, data, value, searchInput, lastName, postalCode) {
  const dataObj = data;
  if (value === 2) {
    dataObj["firstName"] = searchInput;
    dataObj["lastName"] = lastName;
    dataObj["postalCode"] = postalCode;
  } else if (value === 3) {
    dataObj[searchType[value]] = searchInput;
    dataObj["postalCode"] = postalCode;
  } else {
    const formatValue =
      value === 4 ? searchInput.replaceAll("-", "") : searchInput;
    dataObj[searchType[value]] = formatValue;
  }
  return dataObj;
}

function loadingCheck(loading) {
  return loading ? (
    <div>
      <LinearProgress />
    </div>
  ) : null
}

function Sidebar() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState(0);
  const [customers, setCustomers] = React.useState({});
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const searchType = ["email", "vin", "name", "businessName", "phone"];
  const { instance, accounts } = useMsal();
  const [userInputData, setuserInputData] = React.useState({});
  const [searchInput, setsearchInput] = useState("");
  const [lastName, setLastName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [accountName, setAccountName] = useState("");
  const [customerDetails, setcustomerDetails] = useState({});
  const [gifPath, setGifPath] = useState("");
  const [toggle, setToggle] = useState(false);
  const [errorResponse,setErrorResponse]=useState("");
  const [userPermissions, setUserPermissions] = useState([]);

  const setResponse=(response,val)=>{
    if(response.status === 500){
      setCustomersDataRoute(-1);
      setErrorResponse(response.data.description);
    } else {
      setCustomers({ customersData: response.data });
      setCustomersDataRoute(val);
    }
  }

  useEffect(async () => {
    console.log("loading");
    const searchInputs = window.sessionStorage.getItem("searchdata");
    const requiredvalue = Number(window.sessionStorage.getItem("value"));
    const tokenResponse = await tokenAndRolesCapture();
    tokenResponse.idTokenClaims.roles !== undefined && setUserPermissions(tokenResponse.idTokenClaims.roles);
    setAccountName(tokenResponse.account.name);
    if (searchInputs && urlMatch()) {
      setValue(requiredvalue);
      const searchInput = JSON.parse(searchInputs);
      const { REACT_APP_API_URL } = process.env;
      setLoading(true);
      const config = { headers: { "Content-type": "application/json", Authorization: `Bearer ${tokenResponse.idToken}` } };
      await axios.post(`${REACT_APP_API_URL}/gap/search/user`, searchInput.userSearchInputData, config)
        .then((response) => {
          setResponse(response,requiredvalue);
        })
        .catch(({ response }) => {
            setCustomers({ customersData: response.data });
            setCustomersDataRoute(requiredvalue);
        });
      setuserInputData({
        userSearchInputData: searchInput.userSearchInputData,
      });
    }
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("searchdata", JSON.stringify(userInputData));
    window.sessionStorage.setItem("value", value);
  }, [userInputData]);
  async function tokenAndRolesCapture() {
    return instance.acquireTokenSilent({ ...loginRequest, account: accounts[0], })
      .then((response) => {
        return response;
      })
      .catch(async (err) => {
        return instance.acquireTokenRedirect(loginRequest);
      });
  }
  const handleDrawerOpen = () => {
    setOpen(true);
    windowResize();
  };
  const handleClose = () => {
    setToggle(false);
  };
  function setCustomersDataRoute(value) {
    setLoading(false);
    const redirectRoute = customerRoutes(value)
    history.push(redirectRoute)
  }
  const apiCall = async (e, idtoken) => {
    const { REACT_APP_API_URL } = process.env;
    e.preventDefault();
    setLoading(true);
    let data = { searchBy: searchType[value], email: "", vin: "", firstName: "", lastName: "", businessName: "", postalCode: "", phone: "" };
    data = searchData(searchType, data, value, searchInput, lastName, postalCode)
    const config = { headers: { "Content-type": "application/json", Authorization: `Bearer ${idtoken}`, }, };
    await axios.post(`${REACT_APP_API_URL}/gap/search/user`, data, config)
      .then((response) => {
        setResponse(response,value);
      })
      .catch(({ response }) => {
        if(response?.data?.results){
          setCustomers({ customersData: response.data });
          setCustomersDataRoute(value);
        }
        else{
          setCustomersDataRoute(-1);
          setErrorResponse("System is under maintenance.Please try again later!!");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    setuserInputData({ userSearchInputData: data });
    setsearchInput("");
    setLastName("");
    setPostalCode("");
  };
  const handleSearch = async (e) => {
    tokenAndRolesCapture().then((tokenResponse) => {
      apiCall(e, tokenResponse.idToken);
    })
      .catch(async (error) => {
        instance.loginRedirect();
      });
  };

  function rolePermissionCheck(userPermissions) {
    const requiredUserPermissions = process.env.REACT_APP_USER_ROLES.split(",");
    return userPermissions.some((role) =>
      requiredUserPermissions.includes(role)
    );
  }

  if (!rolePermissionCheck(userPermissions)) {
    return <UnAuthenticatedComponent />;
  } else {
    return (
      <div className={classes.root} data-testid="sidebar-testid">
        <CssBaseline />
        <AppBar elevation={0} style={{ backgroundColor: "#ffd0d7", color: "#000000", }} position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open, })}>
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" style={{ backgroundColor: "transparent" }}
              className={clsx({ [classes.hide]: open, })} id="icon-button" >
              <img className={classes.toyoImg} src="/toyota-lexus-logo-7-01.png" />
              <MenuIcon style={{ marginLeft: "40px" }} />
            </IconButton>
            <SearchInputField searchValue={value} handleSearch={handleSearch} searchInput={searchInput} lastName={lastName} postalCode={postalCode} setsearchInput={setsearchInput}
              setLastName={setLastName} setPostalCode={setPostalCode} searchType={searchType} />

            <Grid item style={{ fontWeight: "bold" }}>
              {accountName.toUpperCase()}
            </Grid>
            <Grid item>
              <a href="#" style={{ color: "#000" }}> <NotificationsNoneIcon /></a>
            </Grid>
            <Grid item style={{ paddingLeft: "20px" }}>
              <a href="#" style={{ color: "#000" }}>
                <Tooltip title="Logout">
                  <ExitToAppIcon onClick={() => {
                    window.sessionStorage.clear();
                    history.push("/");
                    instance.logout();
                  }} />
                </Tooltip>
              </a>
            </Grid>
          </Toolbar>
        </AppBar>

        <SideDrawer toggle={toggle} setToggle={setToggle} setGifPath={setGifPath} open={open} setOpen={setOpen} />

        <main className={classes.content}>
          <div className={classes.toolbar} />

          <SearchingTabs searchValue={value} setSearchValue={setValue} />

          {loadingCheck(loading)}

          <Cookbook open={toggle} gifPath={gifPath} handleClose={handleClose} />

          <Grid item xs={12}>
            <CustomerProvider value={[customers.customersData, userInputData.userSearchInputData, tokenAndRolesCapture, [customerDetails, setcustomerDetails],]}>
              <AppRouter response={errorResponse}/>
            </CustomerProvider>
          </Grid>
        </main>
      </div>
    );
  }
}

export default Sidebar;
