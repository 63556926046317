import React from "react";
import CustomerListingComponent from "./CustomerListingComponent";
import * as heads from '../../utilities/customerTableHeads'

const keys = ["email", "status", "firstName", "lastName", "ownershipType"];

const commonHeadCells = [
  heads.EMAIL_HEAD_CELL,
  heads.STATUS_HEAD_CELL,
  heads.FIRST_NAME_HEAD_CELL,
  heads.LAST_NAME_HEAD_CELL,
  heads.ACCOUNTTYPE_HEAD_CELL,
  heads.ACCOUNTCREATEDDATE_HEAD_CELL
];

export default function CustomerCommonListComponent() {
  function createData({ serialNo, email, status, firstName, lastName, ownershipType, accountCreatedDate }, action) {
    return {
      serialNo,
      email,
      status,
      firstName,
      lastName,
      ownershipType,
      accountCreatedDate,
      action,
    };
  }
  return (
    <div data-testid="customerCommonListComponent-testid">
      <CustomerListingComponent
        keys={keys}
        headCells={commonHeadCells}
        createData={createData}
        searchType="common"
      />
    </div>
  );
}
