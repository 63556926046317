import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  iconWidth: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  marginBody: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "30px",
      marginRight: "30px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "25px",
      marginRight: "25px",
      marginTop: "25px",
      marginBottom: "25px",
    },
  },
}));

function CustomerLandingComponent() {
  const classes = useStyles();
  return (
    <div data-testid="customerLandingComponent-testid">
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid className={clsx(classes.marginBody)} item>
          <img className={classes.iconWidth} src="/customer-logo.svg" />
        </Grid>
      </Grid>
    </div>
  );
}

export default CustomerLandingComponent;
