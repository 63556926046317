import React from "react";
import CustomerListingComponent from "./CustomerListingComponent";
import * as heads from '../../utilities/customerTableHeads';

const keys = [
  "vin",
  "email",
  "firstName",
  "lastName",
  "status",
  "ownershipType",
];

const vinHeadCells = [
  heads.VIN_HEAD_CELL,
  heads.EMAIL_HEAD_CELL,
  heads.FIRST_NAME_HEAD_CELL,
  heads.LAST_NAME_HEAD_CELL,
  heads.STATUS_HEAD_CELL,
  heads.OWNERSHIPTYPE_HEAD_CELL,
  heads.ACCOUNTCREATEDDATE_HEAD_CELL
];

export default function CustomerVinListingComponent() {
  function createData({ serialNo, vin, email, status, firstName, lastName, ownershipType, accountCreatedDate }, action) {
    return {
      serialNo,
      vin,
      email,
      firstName,
      lastName,
      status,
      ownershipType,
      accountCreatedDate,
      action,
    };
  }

  return (
    <div data-testid="customerVinListingComponent-testid">
      <CustomerListingComponent
        keys={keys}
        headCells={vinHeadCells}
        createData={createData}
        searchType="vin"
      />
    </div>
  );
}
